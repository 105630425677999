// src/components/FunctionRevisionComparison.js

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import {
  getFunctionRevisionRequest,
  getFunctionRevision2Request,
  getFunctionRevisionsRequest,
} from "redux/functions/action"; // Replace with your redux actions
import SubHeader from "components/SubHeader"; // Adjust the import based on your project
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as FunctionIcon } from "assets/icons/functions.svg";
import FormulaComparison from "./FormulaComparison"; // Adjust the import based on your project
import usePrevious from "utility/hooks/usePrevious";
import RevisionHeader from "./RevisionHeader";
import Revisions from "components/modals/decisionTables/Revisions"; // Modal component
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";

const FunctionRevisionComparison = () => {
  const { setIsLoading } = useContext(MainContext);
  const { id, revisionId, revisionId2 } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isGetFunctionRevisionSuccess,
    isGetFunctionRevision2Success,
    isGetFunctionRevisionsSuccess,
    revisions,
    revision,
    revision2,
  } = useSelector((state) => state.functions);

  const [isRevisionsModalOpen, setIsRevisionsModalOpen] = useState(false);

  // Track previous states to handle side effects
  const prevIsGetFunctionRevisionSuccess = usePrevious(
    isGetFunctionRevisionSuccess
  );
  const prevIsGetFunctionRevision2Success = usePrevious(
    isGetFunctionRevision2Success
  );

  const prevIsGetFunctionRevisionsSuccess = usePrevious(
    isGetFunctionRevisionsSuccess
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(getFunctionRevisionRequest({ id, revisionId }));
    dispatch(getFunctionRevision2Request({ id, revisionId: revisionId2 }));
    return () => setIsLoading(false);
  }, [dispatch, id, revisionId, revisionId2, setIsLoading]);

  // Handle loading state when revisions are loaded
  useEffect(() => {
    if (
      (isGetFunctionRevisionSuccess &&
        prevIsGetFunctionRevisionSuccess === false) ||
      (isGetFunctionRevision2Success &&
        prevIsGetFunctionRevision2Success === false)
    ) {
      if (isGetFunctionRevisionSuccess && isGetFunctionRevision2Success) {
        setIsLoading(false);
      }
    }
  }, [
    isGetFunctionRevisionSuccess,
    isGetFunctionRevision2Success,
    revision,
    revision2,
    prevIsGetFunctionRevisionSuccess,
    prevIsGetFunctionRevision2Success,
    setIsLoading,
  ]);

  const handleOpenRevisionsModal = () => {
    setIsLoading(true);
    dispatch(getFunctionRevisionsRequest(parseInt(id)));
    setIsRevisionsModalOpen(true);
  };

  const handleCloseRevisionsModal = () => {
    setIsRevisionsModalOpen(false);
  };

  // When revisions are fetched, stop loading
  useEffect(() => {
    if (isGetFunctionRevisionsSuccess) {
      setIsLoading(false);
    }
  }, [isGetFunctionRevisionsSuccess, setIsLoading]);

  if (!isGetFunctionRevisionSuccess || !isGetFunctionRevision2Success) {
    return <div>Loading revisions...</div>;
  }

  const settings = [
    {
      id: 1,
      content: (
        <button className="dropdown-item" onClick={handleOpenRevisionsModal}>
          <ClockIcon /> {t("revisions")}
        </button>
      ),
      divider: false,
    },
  ];

  return (
    <>
      <SubHeader
        title={
          <>
            <em>{t("[revision]")} </em> {t("function_definition")}
          </>
        }
        icon={<FunctionIcon />}
        actions={
          <>
            <Link to={`/functions/${id}`} className="mr-2">
              <button className="btn outline">
                <BackIcon />
              </button>
            </Link>
            <div className="btn-group">
              <button
                type="button"
                className="btn primary"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <SettingsIcon />
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                {settings.map((setting) => (
                  <span key={setting.id}>
                    {setting.content}
                    {setting.divider && <div className="dropdown-divider" />}
                  </span>
                ))}
              </div>
            </div>
          </>
        }
      />
      <RevisionHeader revision1={revision} revision2={revision2} />
      <div style={{ padding: "20px" }}>
        <FormulaComparison
          items1={revision.content.items}
          items2={revision2.content.items}
          attributes1={revision.content.attributes}
          attributes2={revision2.content.attributes}
        />
      </div>
      <Revisions
        handleClose={handleCloseRevisionsModal}
        revisionsData={revisions}
        linkTo="functions"
        open={isRevisionsModalOpen}
        elemId={parseInt(id)}
        leftRevisionId={parseInt(revisionId)}
        rightRevisionId={parseInt(revisionId2)}
      />
    </>
  );
};

export default FunctionRevisionComparison;
