import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { MainContext } from "context/contexts";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import SubHeader from "components/SubHeader";
import Revisions from "components/modals/decisionTables/Revisions";
import { useTranslation } from "react-i18next";
import { compareRulesets } from "./compareRulesets";
import "./RulesetRevisionComparison.css";

import {
  getRuleRevisionRequest,
  getRuleRevision2Request,
  getRuleRevisionsRequest,
} from "redux/rulesets/action";

// Operator mappings
const conditionOperatorMap = {
  greater_than: ">",
  greater_than_or_equal_to: ">=",
  less_than: "<",
  less_than_or_equal_to: "<=",
  equal_to: "=",
  not_equal_to: "!=",
  string_contains: "contains",
  string_does_not_contain: "does not contain",
  string_contains_insensitive: "contains (case insensitive)",
  string_does_not_contain_insensitive: "does not contain (case insensitive)",
  starts_with: "starts with",
  starts_with_insensitive: "starts with (case insensitive)",
  ends_with: "ends with",
  ends_with_insensitive: "ends with (case insensitive)",
  time_greater_than: ">",
  time_greater_than_or_equal_to: ">=",
  time_less_than: "<",
  time_less_than_or_equal_to: "<=",
  time_equal_to: "=",
  time_not_equal_to: "!=",
  regex_match: "matches",
  is_null: "is null",
  is_not_null: "is not null",
  equals_insensitive: "equals (case insensitive)",
  array_does_not_contain: "list does not contain",
  array_contains_ci: "list contains (case insensitive)",
};

const actionOperatorMap = {
  s: "set to",
  p: "+",
  m: "-",
  x: "*",
  d: "/",
  a: "add to list",
  h: "max",
  l: "min",
  c: "count",
  g: "sum",
  v: "average",
};

const RulesetRevisionComparison = () => {
  const { setIsLoading } = useContext(MainContext);
  const { id, revisionId, revisionId2 } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    ruleRevision,
    ruleRevision2,
    isGetRuleRevisionSuccess,
    isGetRuleRevision2Success,
    isGetRuleRevisionError,
    isGetRuleRevision2Error,
    revisions,
    isGetRuleRevisionsSuccess,
  } = useSelector((state) => state.ruleSets);

  const [comparison, setComparison] = useState(null);
  const [isRevisionsModalOpen, setIsRevisionsModalOpen] = useState(false);

  useEffect(() => {
    document.title = t("compare_ruleset_revisions");
  }, [t]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getRuleRevisionRequest([id, revisionId]));
    dispatch(getRuleRevision2Request([id, revisionId2]));
  }, [dispatch, id, revisionId, revisionId2, setIsLoading]);

  useEffect(() => {
    if (
      (isGetRuleRevisionError || isGetRuleRevision2Error) &&
      !(isGetRuleRevisionSuccess && isGetRuleRevision2Success)
    ) {
      setIsLoading(false);
    }

    if (
      isGetRuleRevisionSuccess &&
      isGetRuleRevision2Success &&
      ruleRevision &&
      ruleRevision2
    ) {
      setIsLoading(false);
      const result = compareRulesets(ruleRevision, ruleRevision2);
      setComparison(result);
    }
  }, [
    isGetRuleRevisionSuccess,
    isGetRuleRevision2Success,
    isGetRuleRevisionError,
    isGetRuleRevision2Error,
    ruleRevision,
    ruleRevision2,
    setIsLoading,
  ]);

  const handleShowRevisionsModal = () => {
    setIsLoading(true);
    dispatch(getRuleRevisionsRequest(parseInt(id)));
    setIsRevisionsModalOpen(true);
  };

  const handleCloseRevisions = () => {
    setIsRevisionsModalOpen(false);
  };

  if (!isGetRuleRevisionSuccess || !isGetRuleRevision2Success) {
    return (
      <div className="align-items-center col d-flex justify-content-center">
        <span>{t("loading")}...</span>
      </div>
    );
  }

  if (!comparison) {
    return null;
  }

  const { topChanges, rules } = comparison;

  return (
    <>
      <SubHeader
        title={t("compare_ruleset_revisions")}
        actions={
          <Link to={`/rule-sets/${id}`} className="mr-2">
            <button className="btn outline" title={t("back_to_ruleset")}>
              <BackIcon />
            </button>
          </Link>
        }
      />

      <div className="comparison-table-container p-4">
        {topChanges.length > 0 && (
          <div className="mb-4">
            <h3>{t("top_level_changes")}</h3>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>{t("property")}</th>
                  <th>{t("from")}</th>
                  <th>{t("to")}</th>
                </tr>
              </thead>
              <tbody>
                {topChanges.map((change, i) => (
                  <tr key={i}>
                    <td>{change.field}</td>
                    <td>
                      {renderChangedCell(change.oldValue, change.newValue).old}
                    </td>
                    <td>
                      {renderChangedCell(change.oldValue, change.newValue).new}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <h3>{t("rule_changes")}</h3>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{t("rule")}</th>
            </tr>
          </thead>
          <tbody>
            {rules.map((r, index) => {
              const rowClass = r.ruleStatus === "modified" ? "modified" : "";
              return (
                <React.Fragment key={index}>
                  <tr className={rowClass}>
                    <td colSpan={2}>
                      <p>
                        {renderRuleName(r.oldRule, r.newRule, r.ruleStatus)}
                      </p>
                      {getRuleTypeChanges(r.oldRule, r.newRule)}
                      {r.conditionChanges.length > 0 &&
                        r.conditionChanges.map((c, cIndex) =>
                          renderConditionRow(
                            c,
                            0,
                            `${index}-${cIndex}`,
                            r.ruleType,
                            cIndex
                          )
                        )}
                      {r.actionChanges.length > 0 &&
                        r.actionChanges.map((a, aIndex) => (
                          <div key={`${index}-a-${aIndex}`}>
                            {renderAction(a)}
                          </div>
                        ))}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      <Revisions
        handleClose={handleCloseRevisions}
        revisionsData={revisions}
        linkTo="rule-sets"
        open={isRevisionsModalOpen}
        elemId={parseInt(id)}
        leftRevisionId={parseInt(revisionId)}
        rightRevisionId={parseInt(revisionId2)}
      />
    </>
  );
};

function renderRuleName(oldRule, newRule, status) {
  const title = renderFieldDiff(oldRule?.title, newRule?.title, status);
  const name = renderFieldDiff(oldRule?.name, newRule?.name, status);

  let statusElement = null;
  if (status === "added") {
    statusElement = <span className="added badge"> [ADDED] </span>;
  } else if (status === "deleted") {
    statusElement = <span className="deleted badge"> [DELETED] </span>;
  } else if (status === "modified") {
    statusElement = <span className="modified badge"> [MODIFIED] </span>;
  }

  const typeChange =
    oldRule?.type !== newRule?.type ? (
      <>
        <span className="deleted">{oldRule?.type}</span>{" "}
        <span className="added">{newRule?.type}</span>
      </>
    ) : (
      newRule?.type
    );

  const execChange =
    oldRule?.execution_type !== newRule?.execution_type ? (
      <>
        <span className="deleted">{oldRule?.execution_type || "standard"}</span>{" "}
        <span className="added">{newRule?.execution_type || "standard"}</span>
      </>
    ) : (
      newRule?.execution_type || "standard"
    );

  return (
    <div className="rule-name">
      <span className="rule-title">{title}</span>{" "}
      <span className="rule-identifier">({name})</span>
      {statusElement}
      <div className="rule-type">
        Type: {typeChange} | Execution: {execChange}
      </div>
    </div>
  );
}

// Update render function
function renderConditionRow(
  conditionChange,
  level,
  key,
  parentRuleType = "all",
  childIndex = 0
) {
  const { oldCondition, newCondition, status, children } = conditionChange;

  // Get old and new logical operators
  const oldOp = oldCondition?.logical_operator?.toLowerCase();
  const newOp = newCondition?.logical_operator?.toLowerCase();

  // Default logical operators if not defined
  const effectiveOldOp = oldOp || parentRuleType;
  const effectiveNewOp = newOp || parentRuleType;

  // Check if this condition is a group
  const isGroup =
    oldCondition?.condition_type_name === "group" ||
    newCondition?.condition_type_name === "group";

  // Render a single condition line
  function renderSingleCondition(c, childIndex, currentOp) {
    const { oldCondition, newCondition, status } = c;
    const condition = status === "deleted" ? oldCondition : newCondition;

    const conditionLine = formatConditionLine(
      condition?.attribute1_path || condition?.attribute1_value || "",
      conditionOperatorMap[condition?.condition_type_name] ||
        condition?.condition_type_name,
      condition?.attribute2_path || condition?.attribute2_value || ""
    );

    return (
      <div className={`condition-tree-line ${status}`} key={key}>
        <span className="condition-content">
          {/* Only show the logical operator if not the first condition in the group */}
          {childIndex > 0 && (
            <>
              {oldOp !== newOp ? (
                <>
                  <span className="deleted">
                    ~{effectiveOldOp.toUpperCase()}~
                  </span>{" "}
                  <span className="added">{effectiveNewOp.toUpperCase()}</span>
                </>
              ) : (
                <span className="logical-operator">
                  {currentOp === "any" ? "OR" : "AND"}
                </span>
              )}
            </>
          )}
          {conditionLine}
        </span>
      </div>
    );
  }

  // Render the header for a group (showing logical operator differences)
  function renderOperatorHeader(oldO, newO) {
    if (oldO !== newO) {
      return (
        <>
          <span className="deleted">
            ~{oldO === "any" ? "ANY OF:" : "ALL OF:"}~
          </span>{" "}
          <span className="added">
            {newO === "any" ? "ANY OF:" : "ALL OF:"}
          </span>
        </>
      );
    } else {
      return <>{newO === "any" ? "ANY OF:" : "ALL OF:"}</>;
    }
  }

  if (level === 0) {
    // Top-level logic
    if (isGroup) {
      return (
        <div className="condition-tree-wrapper" key={key}>
          <div className="condition-tree-group">
            <div className="condition-tree-header">
              {renderOperatorHeader(effectiveOldOp, effectiveNewOp)}
            </div>
            <div className="condition-tree-children">
              {children?.map((child, index) =>
                renderConditionRow(
                  child,
                  level + 1,
                  `${key}-child-${index}`,
                  effectiveNewOp,
                  index
                )
              )}
            </div>
          </div>
        </div>
      );
    } else {
      // Single condition at the top level
      return (
        <div className="condition-tree-wrapper" key={key}>
          {renderSingleCondition(conditionChange, childIndex, effectiveNewOp)}
        </div>
      );
    }
  } else {
    // Nested logic
    if (isGroup) {
      return (
        <div className="condition-tree-group" key={key}>
          <div className="condition-tree-header">
            {renderOperatorHeader(effectiveOldOp, effectiveNewOp)}
          </div>
          <div className="condition-tree-children">
            {children?.map((child, index) =>
              renderConditionRow(
                child,
                level + 1,
                `${key}-child-${index}`,
                effectiveNewOp,
                index
              )
            )}
          </div>
        </div>
      );
    } else {
      return renderSingleCondition(conditionChange, childIndex, effectiveNewOp);
    }
  }
}

function formatConditionLine(a1, op, a2) {
  if (op === "is null" || op === "is not null") {
    return `${a1} ${op}`;
  }
  return `${a1} ${op} ${a2}`;
}

function renderAction(a) {
  const { oldAction, newAction, status } = a;
  const oldLine = oldAction
    ? formatActionLine(
        oldAction.attribute_path,
        oldAction.action_type,
        oldAction.action_value || oldAction.action_path
      )
    : "";
  const newLine = newAction
    ? formatActionLine(
        newAction.attribute_path,
        newAction.action_type,
        newAction.action_value || newAction.action_path
      )
    : "";

  if (status === "added") {
    return <div className="added">{newLine}</div>;
  } else if (status === "deleted") {
    return <div className="deleted">{oldLine}</div>;
  } else if (status === "modified") {
    return (
      <div>
        <div className="deleted">{oldLine}</div>
        <div className="added">{newLine}</div>
      </div>
    );
  }

  return <div>{oldLine || newLine}</div>;
}

const getRuleTypeChanges = (oldRule, newRule) => {
  const getExecutionTypeText = (type) =>
    type === "standard" || !type ? "apply once" : "apply on all in list";

  const changes = {
    oldExecType: getExecutionTypeText(oldRule?.execution_type),
    newExecType: getExecutionTypeText(newRule?.execution_type),
    oldPath: oldRule?.attribute_path,
    newPath: newRule?.attribute_path,
    hasExecTypeChange: oldRule?.execution_type !== newRule?.execution_type,
    hasPathChange: oldRule?.attribute_path !== newRule?.attribute_path,
  };

  return (
    <>
      {changes.hasExecTypeChange ? (
        <>
          <span className="deleted">{changes.oldExecType}</span>
          <span className="added">{changes.newExecType}</span>
        </>
      ) : (
        <span>{changes.newExecType}</span>
      )}

      {changes.hasPathChange && (
        <>
          {changes.oldPath && (
            <span className="deleted">{changes.oldPath}</span>
          )}
          {changes.newPath && <span className="added">{changes.newPath}</span>}
        </>
      )}
      {newRule?.execution_type === "array" && !changes.hasPathChange && (
        <>
          {" "}
          <span className={!oldRule ? "added" : ""}>
            {newRule.attribute_path}
          </span>
          <span className={!oldRule ? "added" : ""}> that</span>
        </>
      )}
    </>
  );
};

function formatActionLine(path, type, value) {
  const operator = actionOperatorMap[type] || type;

  if (["h", "l", "c", "g", "v"].includes(type)) {
    return `${path} = ${operator}(${value})`;
  }

  if (type === "a") {
    return `${operator} ${value} to ${path}`;
  }

  if (type === "s") {
    return `${path} = ${value}`;
  }

  return `${path} = ${path} ${operator} ${value}`;
}

function renderFieldDiff(oldVal, newVal, status) {
  if (status === "added") {
    return <span className="added">{newVal || "''"}</span>;
  } else if (status === "deleted") {
    return <span className="deleted">{oldVal || "''"}</span>;
  } else if (status === "modified" && oldVal !== newVal) {
    return (
      <>
        <span className="deleted">{oldVal || "''"}</span>{" "}
        <span className="added">{newVal || "''"}</span>
      </>
    );
  }
  return oldVal || newVal || "";
}

function renderChangedCell(oldVal, newVal) {
  if (oldVal !== newVal) {
    return {
      old: <span className="deleted">{oldVal || "''"}</span>,
      new: <span className="added">{newVal || "''"}</span>,
    };
  }
  return { old: oldVal || "", new: newVal || "" };
}

export default RulesetRevisionComparison;
