import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteModelRequest, getModelsRequest } from "redux/models/action";
import usePrevious from "utility/hooks/usePrevious";
import { MainContext } from "context/contexts";
import { ToastOptions } from "components/toastify";
import DeleteConfirm from "components/modals/DeleteConfirm";
import SubHeader from "components/SubHeader";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { getModelStatus, sortByTitle, TableNoItems } from "utility/utility";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle.svg";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const Models = () => {
  const { setIsLoading, isMobile } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    models,
    isGetModelsSuccess,
    isDeletedModelSuccess,
    isGetModelsError,
    isGetModelError,
    isDeletedModelError,
  } = useSelector((state) => state.models);

  const prevIsGetModelsSuccess = usePrevious(isGetModelsSuccess);
  const prevIsDeletedModelSuccess = usePrevious(isDeletedModelSuccess);
  const prevIsGetModelsError = usePrevious(isGetModelsError);
  const prevIsGetModelError = usePrevious(isGetModelError);
  const prevIsDeletedModelError = usePrevious(isDeletedModelError);

  const [modelsClone, setModelsClone] = useState([]);
  const [selectedModel, setSelectedModel] = useState({});
  const [isModelDeleteModalOpen, setIsModelDeleteModalOpen] = useState(false);

  useEffect(() => {
    document.title = t("models_decisimo");
    setIsLoading(true);
    dispatch(getModelsRequest());
  }, []);

  useEffect(() => {
    if (isGetModelsSuccess && prevIsGetModelsSuccess === false) {
      setIsLoading(false);
      const modelsClone = structuredClone(models);
      modelsClone?.sort(sortByTitle);
      setModelsClone(modelsClone);
    }
  }, [isGetModelsSuccess]);

  useEffect(() => {
    if (
      (isGetModelsError && prevIsGetModelsError === false) ||
      (isDeletedModelError && prevIsDeletedModelError === false) ||
      (isGetModelError && prevIsGetModelError === false)
    ) {
      if (isDeletedModelError) {
        deleteFunction();
      }
      setIsLoading(false);
    }
  }, [isGetModelsError, isDeletedModelError, isGetModelError]);

  useEffect(() => {
    if (isDeletedModelSuccess && prevIsDeletedModelSuccess === false) {
      setIsLoading(false);
      deleteFunction();
      toast.warning(t("model_deleted"), ToastOptions);
    }
  }, [isDeletedModelSuccess]);

  const deleteFunction = () => {
    setModelsClone(
      modelsClone.filter((model) => model.model_id !== selectedModel.model_id)
    );
  };

  const handleShowDeleteModal = (model) => {
    setSelectedModel(model);
    setIsModelDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsModelDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedModel({});
    }, 100);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(deleteModelRequest(selectedModel.model_id));
    setIsModelDeleteModalOpen(false);
  };

  return (
    <>
      <SubHeader
        title={t("nav_bar_models")}
        actions={
          <div className="d-flex mb-3 mt-2 text-right">
            <Link to="/model">
              <button className="btn primary-header" title={t("add_model")}>
                <PlusIcon /> <span className="ml-2">{t("model")}</span>
              </button>
            </Link>
          </div>
        }
      />
      <div className="">
        <table className=" table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-3 py-3 ">
                {t("table_title")}
              </th>
              {!isMobile && (
                <th scope="col" className="th-flow col-lg-3 py-3 ">
                  {t("identifier")}
                </th>
              )}
              <th scope="col" className="th-flow col-lg-2 py-3 ">
                {t("status")}
              </th>
              {!isMobile && (
                <th scope="col" className="th-flow col-lg-3 py-3 ">
                  {t("last_change")}
                </th>
              )}
              <th scope="col" className="th-flow col-lg-2 py-3 " />
            </tr>
          </thead>
          <tbody className="">
            {modelsClone?.length > 0
              ? modelsClone.map((model) => {
                  return (
                    <tr key={model.model_id} className="td-flow">
                      <td className="">
                        <Link to={`/models/${model.model_id}`}>
                          {model.title}
                        </Link>
                      </td>
                      {!isMobile && <td className="">{model.name}</td>}
                      <td className="">{getModelStatus(model.status)}</td>
                      {!isMobile && (
                        <td className="">
                          {model.dtime_modified}
                          <HelpCircle
                            data-tip={true}
                            data-for={`modified-${model.model_id}`}
                            className="ml-2 cursor-pointer"
                          />
                          <ReactTooltip
                            type="dark"
                            place="right"
                            effect="solid"
                            id={`modified-${model.model_id}`}
                          >
                            Modified by {model.full_name}
                          </ReactTooltip>
                        </td>
                      )}
                      <td className="d-flex align-items-center justify-content-end border-0">
                        <div>
                          <button
                            type="button"
                            className="btn primary-text border-0 p-0 pb-1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title={t("delete_model")}
                          >
                            <MoreVertical
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </button>
                          <div
                            className="dropdown-menu small-table-dropdown"
                            aria-labelledby="dropdownMenuLink"
                          >
                            {isMobile && (
                              <>
                                <Link
                                  to={`/models/${model.model_id}`}
                                  className="dropdown-item"
                                  title="Edit model"
                                  data-tip={true}
                                  data-for="edit-model"
                                >
                                  <EditIcon /> Edit
                                </Link>
                                <div className="dropdown-divider" />
                              </>
                            )}

                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => handleShowDeleteModal(model)}
                            >
                              <TrashIcon /> {t("delete")}
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetModelsSuccess &&
                models?.length === 0 && <TableNoItems colspan={5} />}
          </tbody>
        </table>
      </div>
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_model")}
        message={
          <span>
            {t("do_you_want_to_delete")} <b>{selectedModel.title}</b>
            {t("model_small")}?
          </span>
        }
        open={isModelDeleteModalOpen}
      />
    </>
  );
};

export default Models;
