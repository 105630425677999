import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import UploadModelConfig from "components/models/UploadModelConfig";
import { useTranslation } from "react-i18next";

const AddModelContainerModal = ({
  show,
  onHide,
  containerImages,
  containerPlugins,
  setSelectedFile,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [modelType, setModelType] = useState("python"); // Default to Python
  const [selectedFile, setSelectedFileLocal] = useState(null);

  const isFormValid = modelType && selectedFile;

  const handleSelectFile = (file) => {
    setSelectedFile(file);
    setSelectedFileLocal(file);
  };

  const handleFormSubmit = () => {
    if (!selectedFile) {
      alert("Please upload a file");
      return;
    }
    //based on id selector of the body, we have there form with fields, we need to collect them

    var model_file_name = selectedFile.name;
    var model_container_image_id = document.querySelector(
      'select[name="model_container_image_id"]'
    ).value;

    // Collect all selected plugins from checkboxes
    var pluginElements = document.querySelectorAll(
      'input[name="plugin[]"]:checked'
    );
    var plugin = Array.from(pluginElements).map((el) => el.value);
    var function_name = document.querySelector(
      'select[name="function_name"]'
    ).value;

    //call the createContainer function
    onSubmit({
      model_file_name,
      model_container_image_id,
      plugin,
      function_name,
    });

    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <h5 className="modal-title">{t("upload_new_model_version")}</h5>
        <button type="button" className="close" onClick={onHide}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body id="addModelContainerForm">
        <UploadModelConfig
          t={t}
          modelType={modelType}
          setModelType={setModelType}
          containerImages={containerImages}
          containerPlugins={containerPlugins}
          handleSelectFile={handleSelectFile}
          selectedFile={selectedFile}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="" className="outline" onClick={onHide}>
          {t("close")}
        </Button>
        <Button
          variant=""
          className="primary my-0"
          disabled={!isFormValid}
          type="submit"
          onClick={handleFormSubmit}
        >
          {t("submit")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddModelContainerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  containerImages: PropTypes.array.isRequired,
  containerPlugins: PropTypes.array.isRequired,
  setSelectedFile: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddModelContainerModal;
