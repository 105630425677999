import { call, put, take, takeLatest, race } from "redux-saga/effects";
import axios from "api/axios";
import {
  createModelFailure,
  createModelRequest,
  createModelSuccess,
  deleteModelFailure,
  deleteModelRequest,
  deleteModelSuccess,
  deleteModelVariableFailure,
  deleteModelVariableRequest,
  deleteModelVariableSuccess,
  getContainerImagesFailure,
  getContainerImagesRequest,
  getContainerImagesSuccess,
  getContainerPluginsFailure,
  getContainerPluginsRequest,
  getContainerPluginsSuccess,
  getModelFailure,
  getModelRequest,
  getModelRunTestResultStatusFailure,
  getModelRunTestResultStatusRequest,
  getModelRunTestResultStatusSuccess,
  getModelsFailure,
  getModelsRequest,
  getModelsSuccess,
  getModelSuccess,
  getModelTestingResultFailure,
  getModelTestingResultRequest,
  getModelTestingResultSuccess,
  getVariablesFailure,
  getVariablesRequest,
  getVariablesSuccess,
  updateModelFailure,
  updateModelRequest,
  updateModelSuccess,
  uploadModelFileRequest,
  uploadModelFileProgress,
  uploadModelFileSuccess,
  uploadModelFileFailure,
  setModelUploadStatus,
  getModelContainersRequest,
  getModelContainersSuccess,
  getModelContainersFailure,
  createNewContainerFailure,
  createNewContainerRequest,
  createNewContainerSuccess,
  getContainerModelDownloadLinkFailure,
  getContainerModelDownloadLinkRequest,
  getContainerModelDownloadLinkSuccess,
} from "redux/models/action";
import { getMimeType } from "utility/utility";
import { channel } from "redux-saga";

function* getModels({ payload }) {
  try {
    const response = yield call(
      axios.get,
      payload ? `/models?page=${payload}` : "/models"
    );
    if (response.status === 200) {
      yield put(getModelsSuccess(response.data));
    }
  } catch (e) {
    yield put(getModelsFailure("e.message"));
  }
}

function* getModel({ payload }) {
  try {
    const url = `models/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getModelSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getModelFailure("e.message"));
  }
}

function* getVariables({ payload }) {
  try {
    const url = `models/${payload}/variables`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getVariablesSuccess(response.data));
    }
  } catch (e) {
    yield put(getVariablesFailure("e.message"));
  }
}

function* updateModel({ payload }) {
  try {
    const url = `models/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 204) {
      yield put(updateModelSuccess());
    }
  } catch (e) {
    yield put(updateModelFailure("e.message"));
  }
}

function* deleteModel({ payload }) {
  try {
    const url = `models/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteModelSuccess());
    }
  } catch (e) {
    yield put(deleteModelFailure("e.message"));
  }
}

function* deleteModelVariable({ payload }) {
  try {
    const url = `models/${payload.id}/variables/${payload.variableId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteModelVariableSuccess());
    }
  } catch (e) {
    yield put(deleteModelVariableFailure("e.message"));
  }
}

function* createModel({ payload }) {
  try {
    const response = yield call(axios.post, "/models", payload);
    if (response.status === 201) {
      yield put(
        createModelSuccess({
          model_id: response.headers.etag,
          model: response.data,
        })
      );
    }
  } catch (e) {
    yield put(createModelFailure("e.message"));
  }
}

function* getContainerImages() {
  try {
    const response = yield call(axios.get, "/container-images");
    if (response.status === 200) {
      yield put(getContainerImagesSuccess(response.data));
    }
  } catch (e) {
    yield put(getContainerImagesFailure("e.message"));
  }
}

function* getContainerPlugins() {
  try {
    const response = yield call(axios.get, "/container-plugins");
    if (response.status === 200) {
      yield put(getContainerPluginsSuccess(response.data));
    }
  } catch (e) {
    yield put(getContainerPluginsFailure("e.message"));
  }
}

function* getModelTestingResult({ payload }) {
  try {
    const response = yield call(
      axios.post,
      `/models/${payload.id}/test`,
      payload.data
    );
    if (response.status === 200) {
      yield put(getModelTestingResultSuccess(response.data));
    }
  } catch (e) {
    yield put(getModelTestingResultFailure("e.message"));
  }
}

function* getRunTestResultStatus({ payload }) {
  try {
    const url = `/models/${payload.id}/test-object/${payload.vectorId}`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getModelRunTestResultStatusSuccess(response.data));
    }
  } catch (e) {
    yield put(getModelRunTestResultStatusFailure(e.message));
  }
}

// Helper function moved outside
function* watchProgress(progressChannel) {
  while (true) {
    const progress = yield take(progressChannel);
    yield put(uploadModelFileProgress({ progress }));
  }
}

function* uploadModelFile({ payload }) {
  const { signedUrl, encryptionKeys, file } = payload;
  const progressChannel = channel();

  try {
    const MAX_FILE_SIZE = 100 * 1024 * 1024;
    if (file.size > MAX_FILE_SIZE) {
      throw new Error("file_too_large");
    }

    yield put(
      setModelUploadStatus({
        isUploading: true,
        progress: 0,
        error: null,
      })
    );

    const upload = () =>
      new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", signedUrl, true);

        xhr.setRequestHeader("Content-Type", getMimeType(file));
        xhr.setRequestHeader("x-goog-encryption-algorithm", "AES256");
        xhr.setRequestHeader(
          "x-goog-encryption-key",
          encryptionKeys.encryption_key
        );
        xhr.setRequestHeader(
          "x-goog-encryption-key-sha256",
          encryptionKeys.encryption_key_hash
        );

        xhr.upload.onprogress = (e) => {
          if (e.lengthComputable) {
            const progress = Math.round((e.loaded / e.total) * 100);
            progressChannel.put(progress);
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve();
          } else {
            reject(new Error(`Upload failed: ${xhr.status}`));
          }
        };

        xhr.onerror = () => reject(new Error("network_error"));
        xhr.send(file);
      });

    // Run upload and progress monitoring in parallel
    yield race({
      upload: call(upload),
      progress: call(watchProgress, progressChannel),
    });

    yield put(uploadModelFileSuccess());
  } catch (error) {
    yield put(uploadModelFileFailure(error.message));
  } finally {
    progressChannel.close();
  }
}
// Add getModelContainers function

function* getModelContainers({ payload }) {
  try {
    const response = yield call(axios.get, `/models/${payload}/containers`);
    if (response.status === 200) {
      yield put(getModelContainersSuccess(response.data));
    }
  } catch (e) {
    yield put(getModelContainersFailure("e.message"));
  }
}

// Add createNewContainer function

function* createNewContainer({ payload }) {
  try {
    const response = yield call(
      axios.post,
      `/models/${payload.model_id}/containers`,
      payload
    );
    if (response.status === 201) {
      yield put(createNewContainerSuccess(response.data));
    }
  } catch (e) {
    yield put(createNewContainerFailure("e.message"));
  }
}

//get container model download link

function* getContainerModelDownloadLink({ payload }) {
  try {
    const response = yield call(
      axios.get,
      `/models/${payload.model_id}/containers/${payload.container_id}`
    );
    if (response.status === 200) {
      yield put(getContainerModelDownloadLinkSuccess(response.data));
    }
  } catch (e) {
    yield put(getContainerModelDownloadLinkFailure("e.message"));
  }
}

// Add to saga watchers
export default function* saga() {
  yield takeLatest(getModelsRequest, getModels);
  yield takeLatest(getModelRequest, getModel);
  yield takeLatest(getVariablesRequest, getVariables);
  yield takeLatest(updateModelRequest, updateModel);
  yield takeLatest(deleteModelRequest, deleteModel);
  yield takeLatest(deleteModelVariableRequest, deleteModelVariable);
  yield takeLatest(createModelRequest, createModel);
  yield takeLatest(getContainerImagesRequest, getContainerImages);
  yield takeLatest(getContainerPluginsRequest, getContainerPlugins);
  yield takeLatest(getModelTestingResultRequest, getModelTestingResult);
  yield takeLatest(getModelRunTestResultStatusRequest, getRunTestResultStatus);
  yield takeLatest(uploadModelFileRequest, uploadModelFile);
  yield takeLatest(getModelContainersRequest, getModelContainers);
  yield takeLatest(createNewContainerRequest, createNewContainer);
  yield takeLatest(
    getContainerModelDownloadLinkRequest,
    getContainerModelDownloadLink
  );
}
