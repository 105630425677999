import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  getFunctionRevisionRequest,
  getFunctionRevisionsRequest,
} from "redux/functions/action";
import usePrevious from "utility/hooks/usePrevious";

import { getStructuredItems } from "utility/utility";
import { MainContext, RevisionContext } from "context/contexts";
import Revisions from "components/modals/decisionTables/Revisions";
import SubHeader from "components/SubHeader";
import FunctionContent from "components/functions/content/FunctionContent";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as FunctionIcon } from "assets/icons/functions.svg";
import { ReactComponent as PlusIcon } from "assets/icons/custom_plus.svg";
import CreateNewItem from "components/modals/CreateNewItem";
import { createItemCopy } from "./utils";
import { useTranslation } from "react-i18next";

const RevisionFunction = () => {
  const { setIsLoading } = useContext(MainContext);
  const { id, revisionId } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isGetFunctionRevisionSuccess,
    isGetFunctionRevisionsSuccess,
    revisions,
    revision,
    isCreatedFunctionError,
    isCreatedFunctionSuccess,
    newFunctionId,
  } = useSelector((state) => state.functions);

  const prevIsGetFunctionRevisionSuccess = usePrevious(
    isGetFunctionRevisionSuccess
  );
  const prevIsGetFunctionRevisionsSuccess = usePrevious(
    isGetFunctionRevisionsSuccess
  );
  const prevIsCreatedFunctionSuccess = usePrevious(isCreatedFunctionSuccess);
  const prevIsCreatedFunctionError = usePrevious(isCreatedFunctionError);

  const [revisionsData, setRevisionsData] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [revisionClone, setRevisionClone] = useState([]);
  const [revisionItems, setRevisionItems] = useState([]);
  const [isFunctionRevisionsModalOpen, setIsFunctionRevisionsModalOpen] =
    useState(false);
  const [visualizeFormula, setVisualizeFormula] = useState(false);
  const [isCreateNewRevisionOpen, setIsCreateNewRevisionOpen] = useState(false);

  useEffect(() => {
    document.title = t("function_revision_decisimo");
    setIsLoading(true);
    dispatch(getFunctionRevisionRequest({ id, revisionId }));
  }, [revisionId]);

  useEffect(() => {
    if (
      isGetFunctionRevisionSuccess &&
      prevIsGetFunctionRevisionSuccess === false
    ) {
      setIsLoading(false);
      setRevisionClone(structuredClone(revision));
      setAttributes(structuredClone(revision?.content?.attributes));
      setRevisionItems(
        getStructuredItems(structuredClone(revision?.content?.items))
      );
    }
  }, [isGetFunctionRevisionSuccess]);

  useEffect(() => {
    if (isCreatedFunctionSuccess && prevIsCreatedFunctionSuccess === false) {
      navigate(`/functions/${newFunctionId}`);
    }
  }, [isCreatedFunctionSuccess]);

  useEffect(() => {
    if (isCreatedFunctionError && prevIsCreatedFunctionError === false) {
      setIsCreateNewRevisionOpen(false);
    }
  }, [isCreatedFunctionError]);

  useEffect(() => {
    if (
      isGetFunctionRevisionsSuccess &&
      prevIsGetFunctionRevisionsSuccess === false
    ) {
      setIsLoading(false);
      setRevisionsData(structuredClone(revisions));
    }
  }, [isGetFunctionRevisionsSuccess]);

  const handleToggleFunctionRevisionsModal = (isOpen = true) => {
    if (isOpen) {
      setIsLoading(true);
      dispatch(getFunctionRevisionsRequest(parseInt(id)));
    }
    setIsFunctionRevisionsModalOpen(isOpen);
  };

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          onClick={() => handleToggleFunctionRevisionsModal()}
          type="button"
        >
          <ClockIcon /> {t("revision")}
        </button>
      ),
      divider: true,
    },
    {
      id: 2,
      content: (
        <button
          className="dropdown-item"
          onClick={() => setIsCreateNewRevisionOpen(true)}
          type="button"
        >
          <PlusIcon /> {t("create_new_function")}
        </button>
      ),
      divider: false,
    },
  ];

  return (
    <>
      <SubHeader
        title={
          <>
            <em>{t("[revision]")} </em> {t("function_definition")}
          </>
        }
        icon={<FunctionIcon />}
        actions={
          <>
            <Link
              to={`/functions/${id}`}
              className="mr-2"
              data-tip={true}
              data-for="back-to-functions-rev"
            >
              <button className="btn outline h-100">
                <BackIcon />
              </button>
            </Link>
            <ReactTooltip
              type="dark"
              place="top"
              effect="solid"
              id="back-to-functions-rev"
            >
              {t("back_to_functions")}
            </ReactTooltip>

            <button
              className="btn outline mr-2"
              type="button"
              onClick={() => setVisualizeFormula(true)}
              data-tip={true}
              data-for="visualize-formula-rev"
            >
              <FunctionIcon />
            </button>
            <ReactTooltip
              type="dark"
              place="top"
              effect="solid"
              id="visualize-formula-rev"
            >
              {t("visualize_formula")}
            </ReactTooltip>
            <div className="btn-group">
              <button
                type="button"
                className="btn primary"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <SettingsIcon />
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                {settings.length > 0 &&
                  settings.map((setting) => {
                    return (
                      <span key={setting.id}>
                        {setting.content}
                        {setting.divider && (
                          <div className="dropdown-divider" />
                        )}
                      </span>
                    );
                  })}
              </div>
            </div>
          </>
        }
      />
      <RevisionContext.Provider value={true}>
        <FunctionContent
          attributes={attributes}
          info={revisionClone?.content?.info}
          items={revisionItems}
          setAttributes={setAttributes}
          visualizeFormula={visualizeFormula}
          setVisualizeFormula={setVisualizeFormula}
        />
      </RevisionContext.Provider>

      <Revisions
        handleClose={() => handleToggleFunctionRevisionsModal(false)}
        revisionsData={revisionsData}
        linkTo={"functions"}
        open={isFunctionRevisionsModalOpen}
        elemId={parseInt(id)}
      />

      <CreateNewItem
        handleClose={() => setIsCreateNewRevisionOpen(false)}
        handleConfirm={() => createItemCopy(revision?.content, dispatch)}
        title={t("function")}
        open={isCreateNewRevisionOpen}
      />
    </>
  );
};

export default RevisionFunction;
