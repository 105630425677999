import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getContainerModelDownloadLinkRequest } from "redux/models/action";
import { ReactComponent as DownloadIcon } from "assets/icons/export.svg";
import {
  generateCloudInstanceName,
  getModelStatus,
  getVectors,
  sortByTitle,
} from "utility/utility";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";

const ContainersModal = ({ show, onHide, containers, modelId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [downloadLinks, setDownloadLinks] = useState({});
  const [containerDetails, setContainerDetails] = useState({});
  const [isDownloading, setIsDownloading] = useState({});
  const [pendingDownloads, setPendingDownloads] = useState(new Set());

  const {
    containerModelDownloadDetails,
    isGetContainerModelDownloadLinkSuccess,
  } = useSelector((state) => state.models);

  const handleGetDownloadLink = (containerId) => {
    dispatch(
      getContainerModelDownloadLinkRequest({
        model_id: modelId,
        container_id: containerId,
      })
    );
  };

  useEffect(() => {
    if (
      isGetContainerModelDownloadLinkSuccess &&
      containerModelDownloadDetails
    ) {
      const containerId =
        containerModelDownloadDetails.model_container.model_container_id;
      const downloadUrl =
        containerModelDownloadDetails.download_link.signed_url;

      setDownloadLinks((prev) => ({
        ...prev,
        [containerId]: downloadUrl,
      }));

      setContainerDetails((prev) => ({
        ...prev,
        [containerId]: containerModelDownloadDetails,
      }));

      if (pendingDownloads.has(containerId)) {
        setPendingDownloads((prev) => {
          const newSet = new Set(prev);
          newSet.delete(containerId);
          return newSet;
        });
        handleDownload(containerId);
      }
    }
  }, [isGetContainerModelDownloadLinkSuccess, containerModelDownloadDetails]);

  const handleDownload = async (containerId) => {
    const downloadLink = downloadLinks[containerId];

    if (!downloadLink) {
      // If download link is not available, request it and mark as pending
      setPendingDownloads((prev) => new Set(prev).add(containerId));
      handleGetDownloadLink(containerId);
      return;
    }

    try {
      setIsDownloading((prev) => ({ ...prev, [containerId]: true }));
      const details = containerDetails[containerId];

      const response = await fetch(downloadLink, {
        headers: {
          "x-goog-encryption-algorithm": "AES256",
          "x-goog-encryption-key":
            details.download_link.encryption_keys.encryption_key,
          "x-goog-encryption-key-sha256":
            details.download_link.encryption_keys.encryption_key_hash,
          "content-type": details.model_container.content_type,
        },
      });

      if (!response.ok) {
        //toast it
        toast.warning(t("download_failed"), ToastOptions);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = details.model_container.title;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      //toast it
      toast.warning(t("download_failed"), ToastOptions);
    } finally {
      toast.info(t("download_started"), ToastOptions);
      setIsDownloading((prev) => ({ ...prev, [containerId]: false }));
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="xlg" centered>
      <Modal.Header>
        <h5 className="modal-title">{t("container_history")}</h5>
        <button type="button" className="close" onClick={onHide}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {containers.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>{t("name")}</th>
                <th>{t("status")}</th>
                <th>{t("active")}</th>
                <th>{t("download")}</th>
              </tr>
            </thead>
            <tbody>
              {containers.map((container) => (
                <tr
                  key={container.model_container_id}
                  className={container.is_active ? "table-success" : ""}
                >
                  <td>{container.title}</td>
                  <td>{getModelStatus(container.status)}</td>
                  <td>
                    {container.is_active ? (
                      <span className="badge bg-success">{t("active")}</span>
                    ) : (
                      <span className="badge bg-secondary">
                        {t("inactive")}
                      </span>
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-link"
                      onClick={() =>
                        handleDownload(container.model_container_id)
                      }
                      title={t("download")}
                      disabled={isDownloading[container.model_container_id]}
                    >
                      {isDownloading[container.model_container_id] ? (
                        <span className="spinner-border spinner-border-sm" />
                      ) : (
                        <DownloadIcon />
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{t("no_containers_found")}</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="" className="outline" onClick={onHide}>
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ContainersModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  containers: PropTypes.array.isRequired,
  modelId: PropTypes.any.isRequired,
};

export default ContainersModal;
