// src/components/FormulaComparison.js

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as FuncIcon } from "assets/icons/function.svg";
import {
  buildFormulaTree,
  traverseFormulaTree,
  compareFormulas,
} from "./utils/utils";
import "./FormulaComparison.css";
import { ComparisonType } from "./utils/types"; // Ensure ComparisonType is imported

const FormulaComparison = ({ items1, items2, attributes1, attributes2 }) => {
  const [formula1, setFormula1] = useState("");
  const [formula2, setFormula2] = useState("");
  const [differences, setDifferences] = useState([]);
  const [attributeMap, setAttributeMap] = useState({});

  useEffect(() => {
    // Build formula1
    const tree1 = buildFormulaTree(items1, attributes1);
    const formulaStr1 = traverseFormulaTree(tree1);
    setFormula1(formulaStr1);

    // Build formula2
    const tree2 = buildFormulaTree(items2, attributes2);
    const formulaStr2 = traverseFormulaTree(tree2);
    setFormula2(formulaStr2);

    // Compare formulas
    const diff = compareFormulas(formulaStr1, formulaStr2);
    setDifferences(diff);

    // Build attribute map
    const map = {};
    attributes1.forEach((attr) => {
      map[`#${attr.fce_attribute_id}`] = attr.name;
    });
    attributes2.forEach((attr) => {
      if (!map[`#${attr.fce_attribute_id}`]) {
        map[`#${attr.fce_attribute_id}`] = attr.name;
      }
    });
    setAttributeMap(map);
  }, [items1, items2, attributes1, attributes2]);

  const compareAttributes = (attributes1, attributes2) => {
    const attributeMap1 = attributes1.reduce((map, attr) => {
      map[attr.name] = attr.attribute_path;
      return map;
    }, {});

    const attributeMap2 = attributes2.reduce((map, attr) => {
      map[attr.name] = attr.attribute_path;
      return map;
    }, {});

    const allKeys = Array.from(
      new Set([...Object.keys(attributeMap1), ...Object.keys(attributeMap2)])
    );

    return allKeys.map((key) => {
      const value1 = attributeMap1[key];
      const value2 = attributeMap2[key];

      if (value1 === value2) {
        return { type: "unchanged", name: key, value: value1 };
      } else if (!value1) {
        return { type: "added", name: key, value: value2 };
      } else if (!value2) {
        return { type: "deleted", name: key, value: value1 };
      } else {
        return {
          type: "modified",
          name: key,
          oldValue: value1,
          newValue: value2,
        };
      }
    });
  };
  const [attributeDifferences, setAttributeDifferences] = useState([]);

  useEffect(() => {
    const attributeResult = compareAttributes(attributes1, attributes2);
    setAttributeDifferences(attributeResult);
  }, [attributes1, attributes2]);
  return (
    <div>
      <h4>Formula Comparison</h4>
      <div className="formula-comparison-container">
        <span>
          <FuncIcon
            style={{ width: "20px", height: "20px", marginRight: "5px" }}
          />
          ={" "}
        </span>
        {differences.map((diff, index) => {
          let displayValue = diff.value;

          // Replace placeholder with actual name if it exists
          if (typeof diff.value === "string" && diff.value.startsWith("#")) {
            displayValue = attributeMap[diff.value] || diff.value;
          }
          if (
            diff.oldValue &&
            typeof diff.oldValue === "string" &&
            diff.oldValue.startsWith("#")
          ) {
            diff.oldValue = attributeMap[diff.oldValue] || diff.oldValue;
          }
          if (
            diff.newValue &&
            typeof diff.newValue === "string" &&
            diff.newValue.startsWith("#")
          ) {
            diff.newValue = attributeMap[diff.newValue] || diff.newValue;
          }

          switch (diff.type) {
            case ComparisonType.ADDED:
              return (
                <span
                  key={index}
                  style={{ color: "green", paddingRight: "5px" }}
                >
                  {displayValue}
                </span>
              );
            case ComparisonType.DELETED:
              return (
                <span
                  key={index}
                  style={{
                    color: "red",
                    textDecoration: "line-through",
                    paddingRight: "5px",
                  }}
                >
                  {displayValue}
                </span>
              );
            case ComparisonType.MODIFIED:
              return (
                <span key={index}>
                  <span
                    style={{
                      color: "red",
                      textDecoration: "line-through",
                      paddingRight: "5px",
                    }}
                  >
                    {diff.oldValue}
                  </span>
                  <span style={{ color: "green" }}>{diff.newValue}</span>
                </span>
              );
            case ComparisonType.UNCHANGED:
            default:
              return (
                <span key={index} style={{ paddingRight: "5px" }}>
                  {displayValue}
                </span>
              );
          }
        })}
      </div>
      <h5 className="mt-4">Attribute Comparison</h5>
      <ul>
        {attributeDifferences.map((diff, index) => {
          if (diff.type === "added") {
            return (
              <li key={index} style={{ color: "green" }}>
                {diff.name}: {diff.value}
              </li>
            );
          }
          if (diff.type === "deleted") {
            return (
              <li
                key={index}
                style={{ color: "red", textDecoration: "line-through" }}
              >
                {diff.name}: {diff.value}
              </li>
            );
          }
          if (diff.type === "modified") {
            return (
              <li key={index}>
                {diff.name}:{" "}
                <span style={{ color: "red", textDecoration: "line-through" }}>
                  {diff.oldValue}
                </span>{" "}
                <span style={{ color: "green" }}>{diff.newValue}</span>
              </li>
            );
          }
          return (
            <li key={index}>
              {diff.name}: {diff.value}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FormulaComparison.propTypes = {
  items1: PropTypes.array.isRequired,
  items2: PropTypes.array.isRequired,
  attributes1: PropTypes.array.isRequired,
  attributes2: PropTypes.array.isRequired,
};

export default FormulaComparison;
