import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as MoreIcon } from "assets/icons/more-vertical.svg";
import { FormulaContext } from "context/contexts";
import { operators } from "utility/utility";
import HiddenInputs from "components/form/HiddenInputs";
import FormulaItem from "components/functions/FormulaItem";

import { useTranslation } from "react-i18next";

const Brackets = ({ item, attributes, revisionMode, path = [], setItem }) => {
  const { handleAddInput } = useContext(FormulaContext);
  const { t } = useTranslation();

  return (
    <>
      {item.fce_item_id && (
        <div className="input-group-prepend pb-1">
          {!revisionMode && (
            <>
              <HiddenInputs
                data={[
                  {
                    name: `fce_itm[${item.fce_item_id}][parent_fce_item_id]`,
                    value: item.parent_fce_item_id
                      ? item.parent_fce_item_id
                      : "null",
                  },
                  {
                    name: `fce_itm[${item.fce_item_id}][item_type]`,
                    value: item.item_type,
                  },
                  {
                    name: `fce_itm[${item.fce_item_id}][fce_item_id]`,
                    value: item.fce_item_id,
                  },
                ]}
              />
              {item.item_type === "f" ? (
                <input
                  type="hidden"
                  name={`fce_itm[${item.fce_item_id}][operand]`}
                  defaultValue={item.operator}
                />
              ) : (
                <span className="input-group-text">(</span>
              )}
            </>
          )}
          {item.item_type == "b" && revisionMode && (
            <span className="input-group-text">(</span>
          )}
        </div>
      )}

      {item.children.map((child, index) => (
        <FormulaItem
          setItem={setItem}
          key={child.fce_item_id}
          item={child}
          path={[...path, index]}
          attributes={attributes}
        />
      ))}
      {!revisionMode && (
        <div>
          <button
            type="button"
            className="btn primary-text mb-1 btn dropdown-toggle-split add-btn mr-1"
            id="formula-menu-null"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <MoreIcon title={t("add_another_formula_item")} />
          </button>
          <div
            className="dropdown-menu"
            aria-labelledby="formula-menu-null"
            style={{
              position: "fixed",
              zIndex: 10000,
            }}
          >
            {operators.map((operator) => {
              return (
                operator.value !== "delete" && (
                  <Link
                    key={operator.value}
                    className="dropdown-item"
                    to="#"
                    tabIndex="-1"
                    value={operator.value}
                    onClick={() => handleAddInput(operator.label, item, path)}
                  >
                    {operator.label}
                  </Link>
                )
              );
            })}
          </div>
        </div>
      )}
      {item.fce_item_id && (
        <div className="input-group-append pb-1">
          <span className="input-group-text">)</span>
        </div>
      )}
    </>
  );
};

Brackets.propTypes = {
  item: PropTypes.object,
  setItem: PropTypes.func,
  attributes: PropTypes.array,
  path: PropTypes.array,
  revisionMode: PropTypes.bool,
};

export default Brackets;
