import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthPasswordRequest } from "redux/auth/action";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import SubHeader from "components/SubHeader";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as XIcon } from "assets/icons/error.svg";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import PasswordStrengthIndicator from "./PasswordStrengthIndicator";

const ChangePassword = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isChangePasswordSuccess, isChangePasswordFailure } = useSelector(
    (state) => state.auth
  );

  const [formValues, setFormValues] = useState({
    old_password: "",
    new_password: "",
    new_password_repeat: "",
  });
  const [visibility, setVisibility] = useState({
    old_password: false,
    new_password: false,
    new_password_repeat: false,
  });

  const [requirements, setRequirements] = useState({
    length: false,
    uppercaseLowercase: false,
    number: false,
    specialChar: false,
  });

  const [hasInteracted, setHasInteracted] = useState(false);
  const [repeatPasswordTouched, setRepeatPasswordTouched] = useState(false);

  useEffect(() => {
    document.title = t("change_password_decisimo");
  }, [t]);

  const validatePassword = (password) => {
    setRequirements({
      length: password.length >= 12,
      uppercaseLowercase: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
    if (name === "new_password") {
      validatePassword(value);
    }
  };

  const toggleVisibility = (field) => {
    setVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const onHandleChangePassword = (e) => {
    e.preventDefault();
    if (formValues.new_password === formValues.new_password_repeat) {
      setIsLoading(true);
      dispatch(updateAuthPasswordRequest(new FormData(e.target)));
    } else {
      toast.error(t("passwords_do_not_match"), ToastOptions);
    }
  };

  const getRequirementColor = (requirement) => {
    if (!hasInteracted) return "#6c757d"; // gray
    return requirement ? "#28a745" : "#dc3545"; // green or red
  };

  const handlePasswordBlur = () => {
    setHasInteracted(true);
  };

  const getPasswordMatchColor = () => {
    if (!repeatPasswordTouched) return ""; // gray
    return formValues.new_password === formValues.new_password_repeat
      ? "#28a745" // green
      : "#dc3545"; // red
  };

  const handleRepeatPasswordBlur = () => {
    //if the length of the password is greater than 0, then the user has interacted with the field
    if (formValues.new_password_repeat.length > 0) {
      setRepeatPasswordTouched(true);
    }
  };

  useEffect(() => {
    if (isChangePasswordSuccess) {
      setIsLoading(false);
      toast.success(t("password_changed"), ToastOptions);
      setFormValues({
        old_password: "",
        new_password: "",
        new_password_repeat: "",
      });
    }
  }, [isChangePasswordSuccess, setIsLoading, t]);

  useEffect(() => {
    if (isChangePasswordFailure) {
      setIsLoading(false);
      toast.error(t("please_fill_in_your_old_password"), ToastOptions);
      setFormValues({
        old_password: "",
        new_password: "",
        new_password_repeat: "",
      });
    }
  }, [isChangePasswordFailure, setIsLoading, t]);

  return (
    <>
      <SubHeader title={t("change_your_password")} />
      <div className="row">
        <form
          className="col-lg-4 col-md-6 col-10"
          onSubmit={onHandleChangePassword}
        >
          {["old_password", "new_password", "new_password_repeat"].map(
            (field, index) => (
              <div className="mb-2" key={index}>
                <label>{t(field)}</label>
                <div className="input-group">
                  <input
                    type={visibility[field] ? "text" : "password"}
                    name={field}
                    value={formValues[field]}
                    onChange={handleChange}
                    onBlur={
                      field === "new_password"
                        ? handlePasswordBlur
                        : field === "new_password_repeat"
                        ? handleRepeatPasswordBlur
                        : undefined
                    }
                    className="form-control"
                    style={
                      field === "new_password_repeat"
                        ? { borderColor: getPasswordMatchColor() }
                        : {}
                    }
                    required
                  />
                  <div className="input-group-append">
                    <button
                      className="form-control"
                      type="button"
                      onClick={() => toggleVisibility(field)}
                      style={{
                        borderLeft: "none",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                      }}
                    >
                      {visibility[field] ? <EyeOffIcon /> : <EyeIcon />}
                    </button>
                  </div>
                </div>
                {field === "new_password_repeat" &&
                  repeatPasswordTouched &&
                  formValues.new_password !==
                    formValues.new_password_repeat && (
                    <small className="text-danger">
                      {t("passwords_do_not_match")}
                    </small>
                  )}
              </div>
            )
          )}
          <div className="mb-3">
            <PasswordStrengthIndicator requirements={requirements} t={t} />
          </div>
          <div className="mb-3 pt-2">
            <p className="mb-0 pb-0">{t("password_requirements")}</p>
            <ul className="list-unstyled pt-0 mt-0 ml-2 small">
              <li
                style={{
                  color: getRequirementColor(requirements.length),
                  marginBottom: "0",
                }}
              >
                {hasInteracted &&
                  (requirements.length ? <CheckIcon /> : <XIcon />)}{" "}
                {t("password_length")}
              </li>
              <li
                style={{
                  color: getRequirementColor(requirements.uppercaseLowercase),
                  marginBottom: "0",
                }}
              >
                {hasInteracted &&
                  (requirements.uppercaseLowercase ? (
                    <CheckIcon />
                  ) : (
                    <XIcon />
                  ))}{" "}
                {t("password_uppercase_lowercase")}
              </li>
              <li
                style={{
                  color: getRequirementColor(requirements.number),
                  marginBottom: "0",
                }}
              >
                {hasInteracted &&
                  (requirements.number ? <CheckIcon /> : <XIcon />)}{" "}
                {t("password_number")}
              </li>
              <li
                style={{
                  color: getRequirementColor(requirements.specialChar),
                  marginBottom: "0",
                }}
              >
                {hasInteracted &&
                  (requirements.specialChar ? <CheckIcon /> : <XIcon />)}{" "}
                {t("password_special_characters")}
              </li>
            </ul>
          </div>
          <button className="btn primary">
            <SaveIcon />
            <span className="ml-2">{t("change_password")}</span>
          </button>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
