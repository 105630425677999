import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import {
  deleteModelVariableRequest,
  getContainerImagesRequest,
  getContainerPluginsRequest,
  getModelContainersRequest,
  createNewContainerRequest,
  uploadModelFileRequest,
} from "redux/models/action";
import { MainContext } from "context/contexts";
import { ToastOptions } from "components/toastify";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import DeleteConfirm from "components/modals/DeleteConfirm";
import Variable from "components/models/Variable";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import {
  generateCloudInstanceName,
  getModelStatus,
  getVectors,
  sortByTitle,
} from "utility/utility";
import { useTranslation } from "react-i18next";
import UploadOverlay from "./UploadOverlay";

import UploadModelConfig from "./UploadModelConfig";
import ContainersModal from "../modals/models/ContainersModal";
import AddModelContainerModal from "../modals/models/AddModelContainerModal";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";

const variable = {
  data_type: "STRING",
  mapping: "",
  name: "",
};

const ModelContent = ({
  model,
  handleSelectFile,
  selectedFile,
  variables,
  editMode = false,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const { autoSuggestData, setIsLoading, setIsEdited } =
    useContext(MainContext);

  const {
    containerImages,
    containerPlugins,
    isGetContainerImagesSuccess,
    isGetContainerPluginsSuccess,
    isDeletedModelVariableSuccess,
    isDeletedModelVariableError,
    isGetContainerImagesError,
    isGetContainerPluginsError,
    isCreateNewContainerSuccess,
    isCreateNewContainerError,
    uploadStatus,
    modelContainers,
    modelDetails,
  } = useSelector((state) => state.models);

  const prevIsGetContainerImagesSuccess = usePrevious(
    isGetContainerImagesSuccess
  );
  const prevIsGetContainerPluginsSuccess = usePrevious(
    isGetContainerPluginsSuccess
  );
  const prevIsDeletedModelVariableSuccess = usePrevious(
    isDeletedModelVariableSuccess
  );
  const prevIsDeletedModelVariableError = usePrevious(
    isDeletedModelVariableError
  );
  const prevIsGetContainerPluginsError = usePrevious(
    isGetContainerPluginsError
  );
  const prevIsGetContainerImagesError = usePrevious(isGetContainerImagesError);

  const prevIsCreateNewContainerSuccess = usePrevious(
    isCreateNewContainerSuccess
  );
  const prevIsCreateNewContainerError = usePrevious(isCreateNewContainerError);

  const [variablesData, setVariablesData] = useState([]);
  const [vectorsDataClone, setVectorsDataClone] = useState([]);
  const [containerImagesClone, setContainerImagesClone] = useState([]);
  const [containerPluginsClone, setContainerPluginsClone] = useState([]);
  const [isModelDeleteModalOpen, setIsModelDeleteModalOpen] = useState(false);
  const [variableId, setVariableId] = useState(undefined);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");

  const [showContainersModal, setShowContainersModal] = useState(false);
  const [showAddContainerModal, setShowAddContainerModal] = useState(false);

  const activeContainer = modelContainers.find(
    (container) => container.is_active
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(getContainerImagesRequest());
    dispatch(getContainerPluginsRequest());

    return () => {
      setIsEdited(false);
    };
  }, []);

  useEffect(() => {
    if (model) {
      setTitle(model.title);
      setName(model.name);
    }
  }, [model]);

  useEffect(() => {
    if (variables) {
      setVariablesData(structuredClone(variables));
    }
  }, [variables]);

  useEffect(() => {
    getVectors(autoSuggestData, setVectorsDataClone, true);
  }, [autoSuggestData]);

  useEffect(() => {
    if (
      isGetContainerImagesSuccess &&
      prevIsGetContainerImagesSuccess === false
    ) {
      const containerImagesClone = structuredClone(containerImages);
      containerImagesClone?.sort(sortByTitle);
      setContainerImagesClone(containerImagesClone);
    }
  }, [isGetContainerImagesSuccess]);

  useEffect(() => {
    if (
      isGetContainerPluginsSuccess &&
      prevIsGetContainerPluginsSuccess === false
    ) {
      setIsLoading(false);
      setContainerPluginsClone(structuredClone(containerPlugins));
    }
  }, [isGetContainerPluginsSuccess]);

  useEffect(() => {
    if (
      isDeletedModelVariableSuccess &&
      prevIsDeletedModelVariableSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(t("variable_deleted"), ToastOptions);
    }
  }, [isDeletedModelVariableSuccess]);

  useEffect(() => {
    if (
      (isDeletedModelVariableError &&
        prevIsDeletedModelVariableError === false) ||
      (isGetContainerImagesError && prevIsGetContainerImagesError === false) ||
      (isGetContainerPluginsError && prevIsGetContainerPluginsError === false)
    ) {
      setIsLoading(false);
    }
  }, [
    isDeletedModelVariableError,
    isGetContainerImagesError,
    isGetContainerPluginsError,
  ]);

  const addVariable = () => {
    setIsEdited(true);
    const variablesClone = structuredClone(variablesData);
    const newVariable = structuredClone(variable);
    newVariable.model_variable_id = `new_${Math.round(Math.random() * 10000)}`;
    variablesClone.push(newVariable);
    setVariablesData(variablesClone);
  };

  const deleteVariable = (id) => {
    setIsEdited(true);
    const variablesClone = structuredClone(variablesData);
    const filteredVariables = variablesClone.filter(
      (variable) => variable.model_variable_id !== id
    );
    setVariablesData(filteredVariables);
  };

  useEffect(() => {
    // Check if the model is new
    if (!model?.model_id) {
      // Add a default variable
      addVariable();
    } else {
      dispatch(getModelContainersRequest(model?.model_id));
    }
  }, [model]);

  const handleShowVariableDeleteModal = (id) => {
    setVariableId(id);
    setIsModelDeleteModalOpen(true);
  };

  const handleVariableDeleteModalClose = () => {
    setVariableId(undefined);
    setIsModelDeleteModalOpen(false);
  };

  const handleVariableDeleteConfirm = () => {
    deleteVariable(variableId);
    handleVariableDeleteModalClose();
    if (!String(variableId).includes("new")) {
      setIsLoading(true);
      dispatch(deleteModelVariableRequest({ id, variableId }));
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );

  const [model_type, setModelType] = useState(); // default value
  const [function_name, setFunctionName] = useState("predict_proba"); // default value

  // Handle model_type change
  const handleModelTypeChange = (e) => {
    setModelType(e);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const data = () => {
        const oldIndex = variablesData.findIndex(
          (variable) => variable.model_variable_id === active.id
        );
        const newIndex = variablesData.findIndex(
          (rule) => rule.model_variable_id === over.id
        );
        const newItemsArray = arrayMove(variablesData, oldIndex, newIndex);

        return newItemsArray;
      };
      setIsEdited(true);
      setVariablesData(data());
    }
  };

  const changeValue = (e, title = false) => {
    setIsEdited(true);
    if (!editMode) {
      if (title) {
        setTitle(e.target.value);
      } else {
        setName(e.target.value);
      }
      if (e.target.value === "0") {
        e.target.classList.add("is-invalid");
      } else if (e.target.classList.contains("is-invalid")) {
        e.target.classList.remove("is-invalid");
      }
    }
  };

  const getStatusClass = (status = "d") => {
    const statuses = [];
    statuses["n"] = "badge-info";
    statuses["s"] = "badge-info";
    statuses["d"] = "badge-success";
    statuses["f"] = "badge-danger";

    return statuses[status];
  };

  // Create a new container
  const handleAddContainer = (data) => {
    setIsLoading(true);
    dispatch(
      createNewContainerRequest({
        model_id: model.model_id,
        ...data,
      })
    );
  };
  //new container addition status
  useEffect(() => {
    if (
      isCreateNewContainerSuccess &&
      prevIsCreateNewContainerSuccess === false
    ) {
      //start upload
      const { signed_url, encryption_keys } = modelDetails;
      setIsEdited(false);
      if (selectedFile && signed_url) {
        dispatch(
          uploadModelFileRequest({
            signedUrl: signed_url,
            encryptionKeys: encryption_keys,
            file: selectedFile,
          })
        );
      }

      setIsLoading(false);
      toast.success(t("container_added"), ToastOptions);
      dispatch(getModelContainersRequest(model.model_id));
    }
  }, [isCreateNewContainerSuccess]);

  useEffect(() => {
    if (isCreateNewContainerError && prevIsCreateNewContainerError === false) {
      setIsLoading(false);
      toast.error(t("container_add_error"), ToastOptions);
    }
  }, [isCreateNewContainerError]);

  useEffect(() => {
    //model tpe change
    if (model_type === "python") {
      setFunctionName("predict_proba");
    } else if (model_type === "julia") {
      setFunctionName("predict_proba");
    } else if (model_type === "r") {
      setFunctionName("predict_proba");
    }
  }, [model_type]);

  return (
    <>
      <div className="container-fluid px-0">
        <div className="card border-0 shadow-sm mb-2">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-12 col-lg pt-3 pt-lg-0">
                <label>{t("table_title")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  onChange={(e) => changeValue(e, true)}
                  defaultValue={title}
                  required
                />
              </div>
              <div className="col-12 col-lg pt-3 pt-lg-0">
                <label>{t("identifier")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={(e) => changeValue(e)}
                  defaultValue={
                    name ? name : !id ? generateCloudInstanceName() : ""
                  }
                  required
                />
              </div>
              {editMode && model.status && (
                <div className="align-items-center align-items-lg-baseline col-12 col-lg-1 d-flex flex-column py-3 py-lg-0">
                  <label>{t("status")}</label>
                  <span className={`badge p-2 ${getStatusClass(model.status)}`}>
                    {getModelStatus(model.status, true)}
                  </span>
                </div>
              )}
              {uploadStatus.isUploading && (
                <UploadOverlay status={uploadStatus} />
              )}
            </div>
          </div>
        </div>
        {!editMode && (
          <UploadModelConfig
            t={t}
            modelType={model_type}
            setModelType={handleModelTypeChange}
            containerImages={containerImages}
            containerPlugins={containerPlugins}
            handleSelectFile={handleSelectFile}
            selectedFile={selectedFile}
          />
        )}
        {model?.model_id && (
          <>
            <div className="card border-0 shadow-sm mb-2">
              <div className="card-body">
                <h4>{t("active_container")}</h4>
                {activeContainer ? (
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p>
                        {t("name")}: {activeContainer.name}
                      </p>
                      <p>
                        {t("endpoint")}: {activeContainer.endpoint_url}
                      </p>
                      <p>
                        {t("status")}: {getModelStatus(activeContainer.status)}
                      </p>
                    </div>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => setShowContainersModal(true)}
                    >
                      <i className="fas fa-clock"></i> {t("view_history")}
                    </button>
                  </div>
                ) : (
                  <p>{t("no_active_container")}</p>
                )}
                <button
                  className="btn outline"
                  onClick={() => setShowContainersModal(true)}
                  type="button"
                >
                  <ClockIcon />
                </button>

                <button
                  className="btn outline ml-2"
                  type="button"
                  onClick={() => setShowAddContainerModal(true)}
                  title={t("upload_new_model_version")}
                >
                  <UploadIcon />
                </button>

                <AddModelContainerModal
                  show={showAddContainerModal}
                  onHide={() => setShowAddContainerModal(false)}
                  containerImages={containerImages}
                  containerPlugins={containerPlugins}
                  setSelectedFile={handleSelectFile}
                  onSubmit={handleAddContainer}
                />

                <ContainersModal
                  show={showContainersModal}
                  onHide={() => setShowContainersModal(false)}
                  containers={modelContainers}
                  modelId={model.model_id}
                />
              </div>
            </div>
          </>
        )}

        <div className="row">
          <div className="col-12">
            <h3 className="mt-md-2 mt-4">{t("model_variables")}</h3>
            <div className="table-responsive mb-3 decision-table-settings border-radius-4">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <table className="table table-pretty vertical-align-middle">
                  <colgroup>
                    <col style={{ width: "2%" }} />
                    <col style={{ width: "33%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "5%" }} />
                  </colgroup>
                  <thead className="bg-transparent border-0">
                    <tr>
                      <th></th>
                      <th>{t("variable_name")}</th>
                      <th>{t("data_type")}</th>
                      <th>{t("value_mapping")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <SortableContext
                      items={variablesData.map((v) => v.model_variable_id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {variablesData.map((variable, index) => (
                        <Variable
                          key={variable.model_variable_id}
                          index={index}
                          variable={variable}
                          vectorsData={vectorsDataClone}
                          handleShowVariableDeleteModal={
                            handleShowVariableDeleteModal
                          }
                        />
                      ))}
                    </SortableContext>
                    <tr>
                      <td colSpan="5" className="p-0">
                        <div className="d-flex justify-content-center add-row-bg py-3">
                          <button
                            className="btn outline"
                            onClick={addVariable}
                            title={t("add_variable")}
                            type="button"
                          >
                            <PlusIcon
                              style={{
                                filter: "brightness(0.5)",
                              }}
                            />
                            <span className="ml-2">{t("add_variable")}</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </DndContext>
            </div>
          </div>
        </div>

        <DeleteConfirm
          handleClose={handleVariableDeleteModalClose}
          handleConfirm={handleVariableDeleteConfirm}
          title={t("delete_model_variable")}
          message={t("delete_model_variable_message")}
          open={isModelDeleteModalOpen}
          variantDelete="primary"
          icon=""
        />
      </div>
    </>
  );
};

ModelContent.propTypes = {
  model: PropTypes.object,
  handleSelectFile: PropTypes.func,
  selectedFile: PropTypes.object,
  variables: PropTypes.array,
  editMode: PropTypes.bool,
};

export default ModelContent;
