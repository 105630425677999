import React from "react";
import Index from "components/dashboard";
import ChangePassword from "components/user/ChangePassword";
import MainLayout from "components/layouts/MainLayout";
import Settings from "components/user/Settings";
import Accounts from "components/user/Accounts";
import Account from "components/user/Account";
import CreateUserAccount from "components/user/CreateUserAccount";
import UserRoles from "components/user/role/Roles";
import ManageRoleRights from "components/user/role/ManageRoleRights";
import Role from "components/user/role/Role";
import SubscriptionSettings from "components/user/subscriptionSettings";
import RuleSets from "components/rulesets";
import RuleSet from "components/rulesets/RuleSet";
import RuleRevision from "components/rulesets/Revision";
import CreateRuleSet from "components/rulesets/AddRuleSet";
import DecisionTables from "components/decisionTables";
import DecisionTable from "components/decisionTables/DecisionTable";
import AddDecisionTableSettings from "components/decisionTables/AddDecisionTableSettings";
import DecisionTableCols from "components/decisionTables/DecisionTableCols";
import DecisionTableRevision from "components/decisionTables/Revision";
import Functions from "components/functions";
import CreateFunction from "components/functions/CreateFunction";
import UpdateFunction from "components/functions/UpdateFunction";
import RevisionFunction from "components/functions/RevisionFunction";
import DataSources from "components/dataSources";
import CreateDataSources from "components/dataSources/CreateDataSources";
import UpdateDataSources from "components/dataSources/UpdateDataSources";
import Models from "components/models";
import CreateModel from "components/models/CreateModel";
import UpdateModel from "components/models/UpdateModel";
import Workflows from "components/workflow";
import UpdateWorkflow from "components/workflow/UpdateWorkflow";
import Releases from "components/releases";
import Release from "components/releases/release";
import EndPoints from "components/endpoints";
import CreateEndPoint from "components/endpoints/CreateEndPoint";
import UpdateEndPoint from "components/endpoints/UpdateEndPoint";
import Deployments from "components/deployments";
import DataObjects from "components/dataObjects";
import DataObject from "components/dataObjects/DataObject";
import Batches from "components/batches";
import CreateBatch from "components/batches/CreateBatch";
import UpdateBatch from "components/batches/UpdateBatch";
import UpdateDeployments from "components/deployments/UpdateDeployments";
import DecisionDesigners from "components/decisionDesign/DecisionDesigners";
import DecisionDesigner from "../components/decisionDesign/DecisionDesigner";
import CreateScorecard from "../components/scorecards/CreateScorecard";
import Scorecard from "../components/scorecards/Scorecard";
import ScorecardRevision from "../components/scorecards/Revision";
import Scorecards from "../components/scorecards";
import DataMarket from "../components/dataMarket";
import DecisionTrees from "../components/decisionTrees";
import DecisionTree from "../components/decisionTrees/DecisionTree";
import CreateDecisionTree from "../components/decisionTrees/CreateDecisionTree";
import DecisionTreeRevision from "components/decisionTrees/DecisionTreeRevision";
import ReleaseValidation from "components/releases/ReleaseValidation";
import AdminCenter from "components/user/AdminCenter";
import ApprovalProcessModal from "components/approvalProcess/approvalProcessModal";
import CreateApprovalSequence from "components/approvalProcess/CreateApprovalSequence";
import ApprovalSequences from "components/approvalProcess/ApprovalSequences";
import ApprovalSequence from "components/approvalProcess/ApprovalSequence";
import AnalysisRecipes from "components/releaseValidation/AnalysisRecipes";
import EditAnalysisRecipe from "components/releaseValidation/EditAnalysisRecipe";
import CreateAnalysisRecipe from "components/releaseValidation/CreateAnalysisRecipe";
import DataMarketServiceDetails from "components/dataMarket/ServiceDetails";
import DecisionTableRevisionComparison from "components/decisionTables/revisionComparison/DecisionTableRevisionComparison";
import ScorecardRevisionComparison from "components/scorecards/revisionComparison/ScorecardRevisionComparison";
import RulesetRevisionComparison from "components/rulesets/revisionComparison/RulesetRevisionComparison";
import ValidationSets from "components/releaseValidation/validationSets";
import FunctionRevisionComparison from "components/functions/revisionComparison/FunctionRevisionComparison";

export const privateRoutes = [
  {
    id: 1,
    path: "/dashboard",
    element: (
      <MainLayout>
        <Index />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 2,
    path: "/change-password",
    element: (
      <MainLayout>
        <ChangePassword />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 3,
    path: "/settings",
    element: (
      <MainLayout>
        <Settings />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 4,
    path: "/user-account",
    element: (
      <MainLayout>
        <CreateUserAccount />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 5,
    path: "/user-accounts",
    element: (
      <MainLayout>
        <Accounts />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 6,
    path: "/user-accounts/:id",
    element: (
      <MainLayout>
        <Account />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 7,
    path: "/user-roles",
    element: (
      <MainLayout>
        <UserRoles />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 8,
    path: "/user-roles/:id",
    element: (
      <MainLayout>
        <Role />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 9,
    path: "/user-role",
    element: (
      <MainLayout>
        <ManageRoleRights />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 10,
    path: "/subscriptions",
    element: (
      <MainLayout>
        <SubscriptionSettings />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 11,
    path: "/rule-set",
    element: (
      <MainLayout>
        <CreateRuleSet />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 12,
    path: "/rule-sets",
    element: (
      <MainLayout>
        <RuleSets />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 13,
    path: "/rule-sets/:id",
    element: (
      <MainLayout>
        <RuleSet />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 14,
    path: "/rule-sets/:id/revisions/:revisionId",
    element: (
      <MainLayout>
        <RuleRevision />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 15,
    path: "/decision-tables",
    element: (
      <MainLayout>
        <DecisionTables />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 16,
    path: "/decision-tables/:id",
    element: (
      <MainLayout>
        <DecisionTable />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 17,
    path: "/decision-tables/:id/cols",
    element: (
      <MainLayout>
        <DecisionTableCols />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 18,
    path: "/decision-table",
    element: (
      <MainLayout>
        <AddDecisionTableSettings />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 19,
    path: "/decision-tables/:id/revisions/:revisionId",
    element: (
      <MainLayout>
        <DecisionTableRevision />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 20,
    path: "/functions",
    element: (
      <MainLayout>
        <Functions />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 21,
    path: "/function",
    element: (
      <MainLayout>
        <CreateFunction />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 22,
    path: "/functions/:id",
    element: (
      <MainLayout>
        <UpdateFunction />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 23,
    path: "/functions/:id/revisions/:revisionId",
    element: (
      <MainLayout>
        <RevisionFunction />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 24,
    path: "/external-data",
    element: (
      <MainLayout>
        <DataSources />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 25,
    path: "/external-data-source",
    element: (
      <MainLayout>
        <CreateDataSources />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 26,
    path: "/external-data-source/:id",
    element: (
      <MainLayout>
        <UpdateDataSources />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 27,
    path: "/models",
    element: (
      <MainLayout>
        <Models />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 28,
    path: "/model",
    element: (
      <MainLayout>
        <CreateModel />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 29,
    path: "/models/:id",
    element: (
      <MainLayout>
        <UpdateModel />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 30,
    path: "/workflows",
    element: (
      <MainLayout>
        <Workflows />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 31,
    path: "/workflows/:id",
    element: (
      <MainLayout>
        <UpdateWorkflow />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 32,
    path: "/workflows/:id/revisions/:revisionId",
    element: (
      <MainLayout>
        <UpdateWorkflow />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 33,
    path: "/releases",
    element: (
      <MainLayout>
        <Releases />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 34,
    path: "/releases/:id",
    element: (
      <MainLayout>
        <Release />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 35,
    path: "/api-endpoints",
    element: (
      <MainLayout>
        <EndPoints />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 36,
    path: "/api-endpoint",
    element: (
      <MainLayout>
        <CreateEndPoint />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 37,
    path: "/api-endpoints/:id",
    element: (
      <MainLayout>
        <UpdateEndPoint />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 38,
    path: "/deployments",
    element: (
      <MainLayout>
        <Deployments />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 39,
    path: "/deployments/:id",
    element: (
      <MainLayout>
        <UpdateDeployments />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 40,
    path: "/data-objects",
    element: (
      <MainLayout>
        <DataObjects />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 41,
    path: "/data-objects/:id",
    element: (
      <MainLayout>
        <DataObject />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 42,
    path: "/batches",
    element: (
      <MainLayout>
        <Batches />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 43,
    path: "/batch",
    element: (
      <MainLayout>
        <CreateBatch />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 44,
    path: "/batches/:id",
    element: (
      <MainLayout>
        <UpdateBatch />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 45,
    path: "/ai-decision-designs",
    element: (
      <MainLayout>
        <DecisionDesigners />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 46,
    path: "/ai-decision-design/:id",
    element: (
      <MainLayout>
        <DecisionDesigner />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 46,
    path: "/approval-sequences",
    element: (
      <MainLayout>
        <ApprovalSequences />
      </MainLayout>
    ),
    exact: true,
  },
  {
    id: 47,
    path: "/scorecards",
    element: (
      <MainLayout>
        <Scorecards />
      </MainLayout>
    ),
  },
  {
    id: 48,
    path: "/scorecard",
    element: (
      <MainLayout>
        <CreateScorecard />
      </MainLayout>
    ),
  },
  {
    id: 49,
    path: "/scorecards/:id",
    element: (
      <MainLayout>
        <Scorecard />
      </MainLayout>
    ),
  },
  {
    id: 50,
    path: "/scorecards/:id/revisions/:revisionId",
    element: (
      <MainLayout>
        <ScorecardRevision />
      </MainLayout>
    ),
    exact: true,
  },
  //data marketplace
  {
    id: 51,
    path: "/data-hub",
    element: (
      <MainLayout>
        <DataMarket />
      </MainLayout>
    ),
  },
  //decision trees
  {
    id: 52,
    path: "/decision-trees",
    element: (
      <MainLayout>
        <DecisionTrees />
      </MainLayout>
    ),
  },
  {
    id: 53,
    path: "/decision-trees/:id",
    element: (
      <MainLayout>
        <DecisionTree />
      </MainLayout>
    ),
  },
  {
    id: 54,
    path: "/decision-trees/:id/revisions/:revisionId",
    element: (
      <MainLayout>
        <DecisionTreeRevision />
      </MainLayout>
    ),
  },
  {
    id: 55,
    path: "/decision-tree",
    element: (
      <MainLayout>
        <CreateDecisionTree />
      </MainLayout>
    ),
  },
  {
    id: 56,
    path: "/release-validation",
    element: (
      <MainLayout>
        <ReleaseValidation />
      </MainLayout>
    ),
  },
  //admin center
  {
    id: 57,
    path: "/admin-center",
    element: (
      <MainLayout>
        <AdminCenter />
      </MainLayout>
    ),
  },
  //approval process modal
  {
    id: 58,
    path: "/approval-process-modal/:id",
    element: <ApprovalProcessModal />,
  },
  //approval sequences
  {
    id: 59,
    path: "/approval-sequence",
    element: (
      <MainLayout>
        <CreateApprovalSequence />
      </MainLayout>
    ),
  },
  {
    id: 60,
    path: "/approval-sequences/:id",
    element: (
      <MainLayout>
        <ApprovalSequence />
      </MainLayout>
    ),
  },
  {
    id: 61,
    path: "/analysis-recipes",
    element: (
      <MainLayout>
        <AnalysisRecipes />
      </MainLayout>
    ),
  },
  {
    id: 62,
    path: "/analysis-recipes/:id",
    element: (
      <MainLayout>
        <EditAnalysisRecipe />
      </MainLayout>
    ),
  },
  {
    id: 63,
    path: "/analysis-recipe",
    element: (
      <MainLayout>
        <CreateAnalysisRecipe />
      </MainLayout>
    ),
  },
  {
    id: 64,
    path: "data-hub-service/:id",
    element: (
      <MainLayout>
        <DataMarketServiceDetails />
      </MainLayout>
    ),
  },
  {
    //revision comparison for decision tables
    id: 65,
    path: "/decision-tables/:id/revisions/:revisionId/compare/:revisionId2",
    element: (
      <MainLayout>
        <DecisionTableRevisionComparison />
      </MainLayout>
    ),
  },
  {
    //revision comparison for scorecards
    id: 66,
    path: "/scorecards/:id/revisions/:revisionId/compare/:revisionId2",
    element: (
      <MainLayout>
        <ScorecardRevisionComparison />
      </MainLayout>
    ),
  },
  //rule revisions
  {
    id: 67,
    path: "/rule-sets/:id/revisions/:revisionId/compare/:revisionId2",
    element: (
      <MainLayout>
        <RulesetRevisionComparison />
      </MainLayout>
    ),
  },
  //validation sets
  {
    id: 68,
    path: "/validation-sets",
    element: (
      <MainLayout>
        <ValidationSets />
      </MainLayout>
    ),
  },
  //function revisions
  {
    id: 69,
    path: "/functions/:id/revisions/:revisionId/compare/:revisionId2",
    element: (
      <MainLayout>
        <FunctionRevisionComparison />
      </MainLayout>
    ),
  },
];
