import { createAction } from "redux-actions";

export const getModelsRequest = createAction("GET_MODELS_REQUEST");
export const getModelsSuccess = createAction("GET_MODELS_SUCCESS");
export const getModelsFailure = createAction("GET_MODELS_FAILURE");

export const getModelRequest = createAction("GET_MODEL_REQUEST");
export const getModelSuccess = createAction("GET_MODEL_SUCCESS");
export const getModelFailure = createAction("GET_MODEL_FAILURE");

export const getVariablesRequest = createAction("GET_VARIABLES_REQUEST");
export const getVariablesSuccess = createAction("GET_VARIABLES_SUCCESS");
export const getVariablesFailure = createAction("GET_VARIABLES_FAILURE");

export const updateModelRequest = createAction("UPDATE_MODEL_REQUEST");
export const updateModelSuccess = createAction("UPDATE_MODEL_SUCCESS");
export const updateModelFailure = createAction("UPDATE_MODEL_FAILURE");

export const deleteModelRequest = createAction("DELETE_MODEL_REQUEST");
export const deleteModelSuccess = createAction("DELETE_MODEL_SUCCESS");
export const deleteModelFailure = createAction("DELETE_MODEL_FAILURE");

export const deleteModelVariableRequest = createAction(
  "DELETE_MODEL_VARIABLE_REQUEST"
);
export const deleteModelVariableSuccess = createAction(
  "DELETE_MODEL_VARIABLE_SUCCESS"
);
export const deleteModelVariableFailure = createAction(
  "DELETE_MODEL_VARIABLE_FAILURE"
);

export const createModelRequest = createAction("CREATE_MODEL_REQUEST");
export const createModelSuccess = createAction("CREATE_MODEL_SUCCESS");
export const createModelFailure = createAction("CREATE_MODEL_FAILURE");

export const getContainerImagesRequest = createAction(
  "GET_CONTAINER_IMAGES_REQUEST"
);
export const getContainerImagesSuccess = createAction(
  "GET_CONTAINER_IMAGES_SUCCESS"
);
export const getContainerImagesFailure = createAction(
  "GET_CONTAINER_IMAGES_FAILURE"
);

export const getContainerPluginsRequest = createAction(
  "GET_CONTAINER_PLUGINS_REQUEST"
);
export const getContainerPluginsSuccess = createAction(
  "GET_CONTAINER_PLUGINS_SUCCESS"
);
export const getContainerPluginsFailure = createAction(
  "GET_CONTAINER_PLUGINS_FAILURE"
);

export const getModelTestingResultRequest = createAction(
  "GET_MODEL_TESTING_RESULT_REQUEST"
);
export const getModelTestingResultSuccess = createAction(
  "GET_MODEL_TESTING_RESULT_SUCCESS"
);
export const getModelTestingResultFailure = createAction(
  "GET_MODEL_TESTING_RESULT_FAILURE"
);

export const getModelRunTestResultStatusRequest = createAction(
  "GET_MODEL_RUN_TEST_RESULT_STATUS_REQUEST"
);
export const getModelRunTestResultStatusSuccess = createAction(
  "GET_MODEL_RUN_TEST_RESULT_STATUS_SUCCESS"
);
export const getModelRunTestResultStatusFailure = createAction(
  "GET_MODEL_RUN_TEST_RESULT_STATUS_FAILURE"
);

export const setModelUploadStatus = createAction("SET_MODEL_UPLOAD_STATUS");

export const uploadModelFileRequest = createAction("UPLOAD_MODEL_FILE_REQUEST");
export const uploadModelFileProgress = createAction(
  "UPLOAD_MODEL_FILE_PROGRESS"
);
export const uploadModelFileSuccess = createAction("UPLOAD_MODEL_FILE_SUCCESS");
export const uploadModelFileFailure = createAction("UPLOAD_MODEL_FILE_FAILURE");

export const getModelContainersRequest = createAction(
  "GET_MODEL_CONTAINERS_REQUEST"
);
export const getModelContainersSuccess = createAction(
  "GET_MODEL_CONTAINERS_SUCCESS"
);
export const getModelContainersFailure = createAction(
  "GET_MODEL_CONTAINERS_FAILURE"
);

//upload a new container
export const createNewContainerRequest = createAction(
  "CREATE_NEW_CONTAINER_REQUEST"
);
export const createNewContainerSuccess = createAction(
  "CREATE_NEW_CONTAINER_SUCCESS"
);
export const createNewContainerFailure = createAction(
  "CREATE_NEW_CONTAINER_FAILURE"
);

//get container model download link
export const getContainerModelDownloadLinkRequest = createAction(
  "GET_CONTAINER_MODEL_DOWNLOAD_LINK_REQUEST"
);

export const getContainerModelDownloadLinkSuccess = createAction(
  "GET_CONTAINER_MODEL_DOWNLOAD_LINK_SUCCESS"
);

export const getContainerModelDownloadLinkFailure = createAction(
  "GET_CONTAINER_MODEL_DOWNLOAD_LINK_FAILURE"
);
