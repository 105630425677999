import { create, all } from "mathjs";

const math = create(all);

export const convertToLatex = (expression) => {
  try {
    // Remove function definition if present
    let processedExp = expression.replace(/f\(x\)\s*=\s*/, "");

    // Pre-process text blocks and known functions
    processedExp = processedExp
      .replace(/\\text{([^}]+)}/g, (match, text) => `"${text}"`)
      .replace(/\\mathrm{(\w+)}/g, (match, text) => text)
      .replace(/date_to_timestamp/g, "dateToTimestamp");

    // Parse the expression using mathjs
    const node = math.parse(processedExp);

    // Convert to LaTeX with a custom handler
    const latex = node.toTex({
      parenthesis: "keep",
      implicit: "show",
      handler: function (node, options) {
        if (node.type === "ConstantNode" && typeof node.value === "string") {
          // Escape underscores in strings
          let val = node.value.replace(/_/g, "\\_");
          return `\\text{${val}}`;
        }
        if (node.type === "OperatorNode" && node.op === "/") {
          // Disable the handler for child nodes to prevent recursion
          const numerator = node.args[0].toTex({
            ...options,
            handler: undefined,
          });
          const denominator = node.args[1].toTex({
            ...options,
            handler: undefined,
          });
          return `\\frac{${numerator}}{${denominator}}`;
        }
        if (node.type === "FunctionNode") {
          if (node.name === "operatorYear") {
            const argument = node.args[0].toTex({
              ...options,
              handler: undefined,
            });
            return `\\mathrm{year}\\left(${argument}\\right)`;
          }
          if (node.name === "dateToTimestamp") {
            const argument = node.args[0].toTex({
              ...options,
              handler: undefined,
            });
            return `\\text{date\\_to\\_timestamp}\\left(${argument}\\right)`;
          }
        }
        // For all other node types, let mathjs handle them
        return undefined;
      },
    });

    return expression.includes("f(x)=") ? `f(x)=${latex}` : latex;
  } catch (error) {
    console.error("Error parsing expression:", error);
    return "Error: Invalid expression";
  }
};
