import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteDecisionTableRequest,
  getDecisionTableRequest,
  getDecisionTableRowsRequest,
  getDecisionTablesRequest,
} from "redux/decisionTables/action";
import usePrevious from "utility/hooks/usePrevious";
import { MainContext } from "context/contexts";
import DeleteConfirm from "components/modals/DeleteConfirm";
import SubHeader from "components/SubHeader";
import { ToastOptions } from "components/toastify";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { Pagination, sortByTitle, TableNoItems } from "utility/utility";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import CreateNewItem from "components/modals/CreateNewItem";
import { createItemCopy, updateDecisionTable } from "./utils";
import { clearTemplates } from "redux/templates/action";
import TemplateModal from "components/modals/Template";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle.svg";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const DecisionTables = () => {
  const { setIsLoading, isMobile } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    decisionTable,
    decisionTableRows,
    isGetDecisionTablesSuccess,
    decisionTables,
    isRemovedDecisionTableSuccess,
    isGetDecisionTablesError,
    isGetDecisionTableSuccess,
    isGetDecisionTableError,
    newDecisionTableId,
    isCreatedDecisionTableSuccess,
    isCreatedDecisionTableError,
    isGetDecisionTableRowsSuccess,
    isGetDecisionTableRowsError,
    isUpdatedDecisionTableSuccess,
    isUpdatedDecisionTableError,
  } = useSelector((state) => state.decisionTables);

  const prevIsRemovedDecisionTableSuccess = usePrevious(
    isRemovedDecisionTableSuccess
  );
  const prevIsGetDecisionTablesSuccess = usePrevious(
    isGetDecisionTablesSuccess
  );
  const prevIsGetDecisionTablesError = usePrevious(isGetDecisionTablesError);
  const prevIsGetDecisionTableSuccess = usePrevious(isGetDecisionTableSuccess);
  const prevIsGetDecisionTableError = usePrevious(isGetDecisionTableError);
  const prevIsCreatedDecisionTableSuccess = usePrevious(
    isCreatedDecisionTableSuccess
  );
  const prevIsCreatedDecisionTableError = usePrevious(
    isCreatedDecisionTableError
  );
  const prevIsGetDecisionTableRowsError = usePrevious(
    isGetDecisionTableRowsError
  );
  const prevIsUpdatedDecisionTableSuccess = usePrevious(
    isUpdatedDecisionTableSuccess
  );
  const prevIsUpdatedDecisionTableError = usePrevious(
    isUpdatedDecisionTableError
  );

  const [selectedDecisionTable, setSelectedDecisionTable] = useState({});
  const [isDecisionTableDeleteModalOpen, setIsDecisionTableDeleteModalOpen] =
    useState(false);
  const [decisionTablesCopy, setDecisionTablesCopy] = useState([]);
  const [isCreateNewFromListOpen, setIsCreateNewFromListOpen] = useState(false);
  const [selectedItemFromList, setSelectedItemFromList] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [isCopy, setIsCopy] = useState(false);
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);

  useEffect(() => {
    document.title = `${t("nav_bar_decision_tables")} - Decisimo`;

    return () => {
      dispatch(clearTemplates());
    };
  }, []);

  useEffect(() => {
    if (currentPage >= 0) {
      setIsLoading(true);
      dispatch(getDecisionTablesRequest(currentPage.toString()));
    }
  }, [currentPage]);

  useEffect(() => {
    if (
      isGetDecisionTablesSuccess &&
      prevIsGetDecisionTablesSuccess === false
    ) {
      setIsCreateNewFromListOpen(false);
      setSelectedItemFromList({});
      setIsLoading(false);
      const decisionTablesClone = structuredClone(decisionTables);
      decisionTablesClone?.sort(sortByTitle);
      setDecisionTablesCopy(decisionTablesClone);
    }
  }, [isGetDecisionTablesSuccess]);

  useEffect(() => {
    if (isGetDecisionTablesError && prevIsGetDecisionTablesError === false) {
      setIsLoading(false);
    }
  }, [isGetDecisionTablesError]);

  useEffect(() => {
    if (
      (isGetDecisionTableError && prevIsGetDecisionTableError === false) ||
      (isCreatedDecisionTableError &&
        prevIsCreatedDecisionTableError === false) ||
      (isGetDecisionTableRowsError &&
        prevIsGetDecisionTableRowsError === false) ||
      (isUpdatedDecisionTableError && prevIsUpdatedDecisionTableError === false)
    ) {
      if (isUpdatedDecisionTableError) {
        setIsTemplatesModalOpen(false);
      }
      setIsCreateNewFromListOpen(false);
      setSelectedItemFromList({});
      setIsLoading(false);
    }
  }, [
    isUpdatedDecisionTableError,
    isGetDecisionTableRowsError,
    isGetDecisionTableError,
    isCreatedDecisionTableError,
  ]);

  useEffect(() => {
    if (
      isRemovedDecisionTableSuccess &&
      prevIsRemovedDecisionTableSuccess === false
    ) {
      setIsLoading(false);
      setDecisionTablesCopy(
        decisionTablesCopy.filter(
          (table) =>
            table.decision_table_id !== selectedDecisionTable.decision_table_id
        )
      );
      handleClose();
      toast.warning(t("decision_table_deleted"), ToastOptions);
    }
  }, [isRemovedDecisionTableSuccess]);

  useEffect(() => {
    if (
      isCreatedDecisionTableSuccess &&
      prevIsCreatedDecisionTableSuccess === false
    ) {
      setIsLoading(false);
      if (!isTemplatesModalOpen) {
        setIsCopy(true);
        dispatch(getDecisionTableRequest(newDecisionTableId));
      }
    }
  }, [isCreatedDecisionTableSuccess]);

  useEffect(() => {
    if (
      isGetDecisionTableSuccess &&
      prevIsGetDecisionTableSuccess === false &&
      !isCopy &&
      !isTemplatesModalOpen
    ) {
      createItemCopy(decisionTable, dispatch, t("copy_of"), t("copy"));
    } else if (isGetDecisionTableRowsSuccess && isCopy) {
      setIsCopy(false);
      updateDecisionTable(
        decisionTable,
        decisionTableRows,
        newDecisionTableId,
        dispatch
      );
    }
  }, [
    isGetDecisionTableRowsSuccess,
    newDecisionTableId,
    isGetDecisionTableSuccess,
  ]);

  useEffect(() => {
    if (
      isUpdatedDecisionTableSuccess &&
      prevIsUpdatedDecisionTableSuccess === false
    ) {
      if (isTemplatesModalOpen) {
        setIsTemplatesModalOpen(false);
        navigate(`/decision-tables/${newDecisionTableId}`);
      }
      dispatch(getDecisionTablesRequest());
      toast.success(t("decision_table_created"), ToastOptions);
    }
  }, [isUpdatedDecisionTableSuccess]);

  const handleShowDeleteModal = (selectedDecisionTableInfo) => {
    setSelectedDecisionTable(selectedDecisionTableInfo);
    setIsDecisionTableDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsDecisionTableDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedDecisionTable({});
    }, 100);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(
      deleteDecisionTableRequest(selectedDecisionTable.decision_table_id)
    );
  };

  const handleCreateNewFromListConfirm = (id) => {
    dispatch(getDecisionTableRequest(id));
    dispatch(getDecisionTableRowsRequest(id));
  };

  const handleOpenTemplatesModal = () => {
    setIsTemplatesModalOpen(true);
  };

  return (
    <>
      <SubHeader
        alt={t("nav_bar_decision_tables")}
        title={t("nav_bar_decision_tables")}
        actions={
          <div className="d-flex flex-wrap my-3 text-right white-space-pre btn-group">
            <Link
              to="/decision-table"
              title={t("create_decision_table")}
              className="btn primary-header border-radius-left-4"
            >
              <PlusIcon /> <span className="ml-2">{t("decision_table")}</span>
            </Link>
            <div className="btn-group">
              <button
                title={t("create_decision_table_template")}
                className="btn dropdown-toggle dropdown-toggle-split primary border-radius-right-4"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="sr-only">{t("toggle_dropdown")}</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                <span>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={handleOpenTemplatesModal}
                  >
                    <PlusIcon
                      style={{
                        filter: "brightness(0.5)",
                      }}
                    />
                    <span className="ml-2">{t("create_from_template")}</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        }
      />
      <div className="mb-4">
        <table className=" table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-4 py-3 ">
                {t("table_title")}
              </th>
              {!isMobile && (
                <>
                  <th scope="col" className="th-flow col-lg-4 py-3 ">
                    {t("identifier")}
                  </th>

                  <th scope="col" className="th-flow col-lg-4 py-3 ">
                    {t("last_change")}
                  </th>
                </>
              )}
              <th scope="col" className="th-flow col-lg-4 py-3 " />
            </tr>
          </thead>
          <tbody className="">
            {decisionTablesCopy?.length > 0
              ? decisionTablesCopy.map((table) => {
                  return (
                    <tr key={table.decision_table_id} className="td-flow">
                      <td className="">
                        <Link
                          to={`/decision-tables/${table.decision_table_id}`}
                        >
                          {table.title}
                        </Link>
                      </td>
                      {!isMobile && (
                        <>
                          <td className=" ">{table.name}</td>
                          <td className=" ">
                            {table.dtime_modified}
                            <HelpCircle
                              data-tip={true}
                              data-for={`modified-${table.decision_table_id}`}
                              className="ml-2 cursor-pointer"
                            />
                            <ReactTooltip
                              type="dark"
                              place="right"
                              effect="solid"
                              id={`modified-${table.decision_table_id}`}
                            >
                              {t("modified_by")} {table.full_name}
                            </ReactTooltip>
                          </td>
                        </>
                      )}
                      <td className="d-flex align-items-center justify-content-end border-0">
                        <div>
                          <button
                            title={t("copy_or_delete_decision_table")}
                            type="button"
                            className="btn primary-text border-0 p-0 pb-1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <MoreVertical
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </button>

                          <div
                            className="dropdown-menu table-dropdown"
                            aria-labelledby="dropdownMenuLink"
                          >
                            {isMobile && (
                              <Link
                                to={`/decision-tables/${table.decision_table_id}`}
                                className="dropdown-item"
                                title="Edit decision table"
                              >
                                <EditIcon /> {t("edit")}
                              </Link>
                            )}
                            <Link
                              to={`/decision-tables/${table.decision_table_id}/cols`}
                              className="dropdown-item text-body"
                            >
                              <SettingsIcon /> {t("settings")}
                            </Link>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => {
                                const { title, decision_table_id } = table;
                                setIsCreateNewFromListOpen(true);
                                setSelectedItemFromList({
                                  title,
                                  decision_table_id,
                                });
                              }}
                            >
                              <CopyIcon /> {t("make_a_copy")}
                            </button>
                            <div className="dropdown-divider" />
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => handleShowDeleteModal(table)}
                            >
                              <TrashIcon /> {t("delete")}
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetDecisionTablesSuccess &&
                decisionTables?.length === 0 && <TableNoItems />}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        itemsLength={decisionTablesCopy?.length}
        setCurrentPage={setCurrentPage}
      />
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_decision_table")}
        message={
          <span>
            {t("do_you_want_delete")} <b>{selectedDecisionTable.title}</b>{" "}
            {t("decision_table_small_caps")} ?
          </span>
        }
        open={isDecisionTableDeleteModalOpen}
      />

      {isTemplatesModalOpen && (
        <TemplateModal
          open={isTemplatesModalOpen}
          handleClose={() => setIsTemplatesModalOpen(false)}
          type={"decision_table"}
          title={t("decision_table")}
        />
      )}

      <CreateNewItem
        handleClose={() => setIsCreateNewFromListOpen(false)}
        handleConfirm={handleCreateNewFromListConfirm}
        open={isCreateNewFromListOpen}
        listMode={true}
        keyItemId="decision_table_id"
        item={selectedItemFromList}
      />
    </>
  );
};

export default DecisionTables;
