import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  forgotPasswordFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  getAuthUserFailure,
  getAuthUserRequest,
  getAuthUserSuccess,
  getLoginFailure,
  getLoginRequest,
  getLoginSuccess,
  requireOTP,
  signUsingOTPFailure,
  signUsingOTPRequest,
  signUsingOTPSuccess,
  signOutFailure,
  signOutRequest,
  signUpFailure,
  signUpRequest,
  signUpSuccess,
  updateAuthPasswordFailure,
  updateAuthPasswordRequest,
  updateAuthPasswordSuccess,
  updateSettingsFailure,
  updateSettingsRequest,
  updateSettingsSuccess,
  resendOTPSuccess,
  resendOTPFailure,
  resendOTPRequest,
} from "redux/auth/action";
import { privateRoutes } from "routes";

function* login({ payload }) {
  try {
    const response = yield call(axios.post, "/user/login", payload);

    if (response.status === 200) {
      const { token, tenant } = response.data;
      localStorage.setItem("token", token);

      if (!localStorage.getItem("sidebar_open")) {
        localStorage.setItem("sidebar_open", "false");
      }
      localStorage.setItem("tenant", tenant);
      yield put(getAuthUserRequest());
      yield put(getLoginSuccess(response.data));
    }
  } catch (e) {
    if (e.response && e.response.status === 428) {
      // Handle 428 status code specifically
      const email = payload.get("email");
      localStorage.setItem("login_email", email);
      yield put(requireOTP());
    } else if (e.response && e.response.status === 429) {
      // Handle 429 status code specifically
      yield put(getLoginFailure("Too many requests"));
    } else {
      // Handle other errors
      yield put(getLoginFailure(e.message));
    }
  }
}

//login using OTP
function* loginUsingOTP({ payload }) {
  try {
    const response = yield call(axios.post, "/user/otp", payload);
    if (response.status === 200) {
      const { token, tenant } = response.data;
      localStorage.setItem("token", token);
      localStorage.setItem("tenant", tenant);
      yield put(getAuthUserRequest());
      yield put(signUsingOTPSuccess(response.data));
    }
  } catch (e) {
    if (e.response && e.response.status === 429) {
      // Handle 429 status code specifically
      yield put(signUsingOTPFailure("Too many requests"));
    } else {
      yield put(signUsingOTPFailure(e.message));
    }
  }
}

function* register({ payload }) {
  try {
    const response = yield call(axios.post, "/user/signup", payload);
    if (response.status === 201) {
      yield put(signUpSuccess());
      yield call(login, {
        payload: payload,
      });
    }
  } catch (e) {
    yield put(
      signUpFailure({
        e: e.message,
        status: e.response.status,
        data: e.response.data,
      })
    );
  }
}

function* getAuthUser() {
  try {
    const response = yield call(axios.get, "/user");
    if (response.status === 200) {
      yield put(getAuthUserSuccess(response.data));
    }
  } catch (e) {
    yield put(getAuthUserFailure("e.message"));
  }
}

function* forgotPassword({ payload }) {
  try {
    const response = yield call(axios.post, "/user/reset", payload);
    if (response.status === 200) {
      yield put(forgotPasswordSuccess());
    }
  } catch (e) {
    yield put(forgotPasswordFailure(e.message));
  }
}

function* changePassword({ payload }) {
  try {
    const response = yield call(axios.post, "/user/change-password", payload);
    if (response.status === 200) {
      yield put(updateAuthPasswordSuccess());
    }
  } catch (e) {
    yield put(updateAuthPasswordFailure(e.message));
  }
}

function* changeAuthSettings({ payload }) {
  try {
    const response = yield call(axios.put, "/user", payload);
    if (response.status === 200) {
      yield put(updateSettingsSuccess());
    }
  } catch (e) {
    yield put(updateSettingsFailure(e.message));
  }
}

function* signOut({ payload }) {
  try {
    const headers = {
      Authorization: payload,
    };
    const response = yield call(axios.get, "/user/logout", { headers });
    if (response.status === 200) {
      window.location.replace("/login");
      localStorage.removeItem("copiedStep");
      localStorage.removeItem("copiedWorkflow");
      localStorage.removeItem("token");
      localStorage.removeItem("tenant");
      localStorage.removeItem("user_language");
      const route = privateRoutes.find(
        (route) => route.path === window.location.pathname
      );
      if (route) {
        localStorage.setItem("prevURL", window.location.pathname);
      } else {
        localStorage.removeItem("prevURL");
      }
    }
  } catch (e) {
    yield put(signOutFailure(e.message));
  }
}

//resend otp - like sending otp, but without the OTP parameter
//this is used to resend the OTP to the user
function* resendOTP({ payload }) {
  try {
    const response = yield call(axios.post, "/user/otp", payload);
    if (response.status === 200) {
      yield put(resendOTPSuccess());
    }
  } catch (e) {
    yield put(resendOTPFailure(e.message));
  }
}

export default function* saga() {
  yield takeLatest(getLoginRequest, login);
  yield takeLatest(signUpRequest, register);
  yield takeLatest(forgotPasswordRequest, forgotPassword);
  yield takeLatest(updateAuthPasswordRequest, changePassword);
  yield takeLatest(updateSettingsRequest, changeAuthSettings);
  yield takeLatest(getAuthUserRequest, getAuthUser);
  yield takeLatest(signOutRequest, signOut);
  yield takeLatest(signUsingOTPRequest, loginUsingOTP);
  yield takeLatest(resendOTPRequest, resendOTP);
}
