import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import usePrevious from "utility/hooks/usePrevious";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import {
  createWorkflowReleaseRequest,
  createWorkflowStepRequest,
  deleteLinkRequest,
  deleteWorkflowRequest,
  getWorkflowOptionConditionTypesRequest,
  getWorkflowRequest,
  getWorkflowRevisionRequest,
  getWorkflowRevisionsRequest,
  getWorkflowsRequest,
  getWorkflowStepTypesRequest,
  updateWorkflowRequest,
  updateWorkflowStepRequest,
  useCaseWorkflowRequest,
} from "redux/workflows/action";
import { getRuleSetsRequest } from "redux/rulesets/action";
import { getFunctionsRequest } from "redux/functions/action";
import { getDecisionTablesRequest } from "redux/decisionTables/action";
import { getModelsRequest } from "redux/models/action";
import { getScorecardsRequest } from "redux/scorecard/action";
import { fetchDecisionTreesRequest } from "redux/decisionTrees/action";
import { getDataSourcesRequest } from "redux/dataSources/action";
import { sortById, sortByTitle, sortByType } from "utility/utility";
import DeleteConfirm from "components/modals/DeleteConfirm";
import EditWorkflow from "components/modals/workflow/Edit";
import ReleaseWorkflow from "components/modals/workflow/Release";
import SubHeader from "components/SubHeader";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as ReleaseIcon } from "assets/icons/releases.svg";
import { ReactComponent as OptionsIcon } from "assets/icons/options.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import Diagram from "components/workflow/drawflow/Diagram";
import { MainContext, WorkflowContext } from "context/contexts";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import Revisions from "components/modals/ruleSet/Revisions";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as CrossHairIcon } from "assets/icons/crosshair.svg";
import { ReactComponent as PlusIcon } from "assets/icons/custom_plus.svg";
import { ReactComponent as DecisionFlowsIcon } from "assets/icons/decision_flows.svg";
import CreateNewItem from "../modals/CreateNewItem";
import { createWorkflowCopy, getTypeIdKey } from "./utils";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import ReactTooltip from "react-tooltip";

const UpdateWorkflow = () => {
  const { setIsLoading, setIsEdited, isMobile } = useContext(MainContext);
  const dispatch = useDispatch();
  const workflowRef = useRef();
  const navigate = useNavigate();
  const { id, revisionId } = useParams();
  const { t } = useTranslation();

  const {
    workflowRevisions,
    workflowRevision,
    workflow,
    workflowStepTypes,
    isGetWorkflowSuccess,
    isGetWorkflowError,
    isGetWorkflowStepTypesSuccess,
    isGetWorkflowStepTypesError,
    isUpdatedWorkflowSuccess,
    isUpdatedWorkflowError,
    isDeletedWorkflowSuccess,
    isDeletedWorkflowError,
    isDeletedLinkSuccess,
    isDeletedLinkError,
    isCreatedWorkflowReleaseSuccess,
    isCreatedWorkflowReleaseError,
    releaseETag,
    isDeletedWorkflowStepSuccess,
    isDeletedWorkflowStepError,
    isCreatedWorkflowStepSuccess,
    isCreatedWorkflowStepError,
    workflows,
    isGetWorkflowsSuccess,
    isGetWorkflowsError,
    isUpdatedWorkflowStepSuccess,
    isUpdatedWorkflowStepError,
    isGetWorkflowOptionConditionTypesSuccess,
    isGetWorkflowOptionConditionTypesError,
    workflowOptionConditionTypes,
    isGetWorkflowRevisionsSuccess,
    isGetWorkflowRevisionsError,
    isGetWorkflowRevisionSuccess,
    isGetWorkflowRevisionError,
    isCreatedWorkflowSuccess,
    isCreatedWorkflowError,
    eTag,
    createdStepId,
    useCaseWorkflow,
  } = useSelector((state) => state.workflows);

  const { isGetRuleSetsSuccess, ruleSets } = useSelector(
    (state) => state.ruleSets
  );

  const { isGetFunctionsSuccess, functions } = useSelector(
    (state) => state.functions
  );

  const { isGetModelsSuccess, models } = useSelector((state) => state.models);

  const { isGetDecisionTablesSuccess, decisionTables } = useSelector(
    (state) => state.decisionTables
  );

  const { isGetDataSourcesSuccess, dataSources } = useSelector(
    (state) => state.dataSources
  );
  const { isGetScorecardsSuccess, scorecards } = useSelector(
    (state) => state.scorecard ?? {}
  );

  const { decisionTrees, isFetchTreeSuccess } = useSelector(
    (state) => state.decisionTrees
  );

  const prevIsDeletedLinkSuccess = usePrevious(isDeletedLinkSuccess);
  const prevIsGetWorkflowRevisionsSuccess = usePrevious(
    isGetWorkflowRevisionsSuccess
  );
  const prevIsGetWorkflowRevisionsError = usePrevious(
    isGetWorkflowRevisionsError
  );
  const prevIsGetWorkflowRevisionSuccess = usePrevious(
    isGetWorkflowRevisionSuccess
  );
  const prevIsGetWorkflowRevisionError = usePrevious(
    isGetWorkflowRevisionError
  );
  const prevIsDeletedLinkError = usePrevious(isDeletedLinkError);
  const prevIsGetWorkflowSuccess = usePrevious(isGetWorkflowSuccess);
  const prevIsGetWorkflowError = usePrevious(isGetWorkflowError);
  const prevIsGetWorkflowsError = usePrevious(isGetWorkflowsError);
  const prevIsGetWorkflowOptionConditionTypesError = usePrevious(
    isGetWorkflowOptionConditionTypesError
  );

  const prevIsGetWorkflowStepTypesSuccess = usePrevious(
    isGetWorkflowStepTypesSuccess
  );
  const prevIsGetWorkflowStepTypesError = usePrevious(
    isGetWorkflowStepTypesError
  );
  const prevIsUpdatedWorkflowStepSuccess = usePrevious(
    isUpdatedWorkflowStepSuccess
  );
  const prevIsUpdatedWorkflowStepError = usePrevious(
    isUpdatedWorkflowStepError
  );
  const prevIsUpdatedWorkflowSuccess = usePrevious(isUpdatedWorkflowSuccess);
  const prevIsUpdatedWorkflowError = usePrevious(isUpdatedWorkflowError);
  const prevIsDeletedWorkflowSuccess = usePrevious(isDeletedWorkflowSuccess);
  const prevIsDeletedWorkflowError = usePrevious(isDeletedWorkflowError);
  const prevIsDeletedWorkflowStepSuccess = usePrevious(
    isDeletedWorkflowStepSuccess
  );
  const prevIsDeletedWorkflowStepError = usePrevious(
    isDeletedWorkflowStepError
  );
  const prevIsCreatedWorkflowReleaseSuccess = usePrevious(
    isCreatedWorkflowReleaseSuccess
  );
  const prevIsCreatedWorkflowStepSuccess = usePrevious(
    isCreatedWorkflowStepSuccess
  );
  const prevIsCreatedWorkflowReleaseError = usePrevious(
    isCreatedWorkflowReleaseError
  );
  const prevIsCreatedWorkflowStepError = usePrevious(
    isCreatedWorkflowStepError
  );
  const prevIsCreatedWorkflowSuccess = usePrevious(isCreatedWorkflowSuccess);
  const prevIsCreatedWorkflowError = usePrevious(isCreatedWorkflowError);

  const [workflowClone, setWorkflowClone] = useState({});
  const [stepTypes, setStepTypes] = useState([]);
  const [initialNodes, setInitialNodes] = useState([]);
  const [workflowName, setWorkflowName] = useState("");
  const [releaseId, setReleaseId] = useState("");
  const [isReleaseModalOpen, setIsReleaseModalOpen] = useState(false);
  const [isDecisionFlowDeleteModalOpen, setIsDecisionFlowDeleteModalOpen] =
    useState(false);
  const [isDecisionFlowEditModalOpen, setIsDecisionFlowEditModalOpen] =
    useState(false);
  const [mounting, setMounting] = useState(true);
  const [isWorkflowRevisionsModalOpen, setIsWorkflowRevisionsModalOpen] =
    useState(false);
  const [workflowRevisionsData, setWorkflowRevisionsData] = useState([]);
  const [isCreateNewRevisionOpen, setIsCreateNewRevisionOpen] = useState(false);
  const [revisionStepIndex, setRevisionStepIndex] = useState(0);
  const [isUpdatedStep, setIsUpdatedStep] = useState(false);
  const [copiedStep, setCopiedStep] = useState({});
  const [xStepPosition, setXstepPosition] = useState(200);
  const [yStepPosition, setYstepPosition] = useState(50);
  const [sortedNodesClone, setSortedNodesClone] = useState([]);
  const [coordinates, setCoordinates] = useState({});
  const [isPasted, setIsPasted] = useState(false);
  const [isCanvasDragging, setIsCanvasDragging] = useState(true);
  const [linkId, setLinkId] = useState({});
  const [titleHovered, setTitleHovered] = useState(false);

  const copiedWorkflow = JSON.parse(localStorage.getItem("copiedWorkflow"));
  const copiedStepLocal = JSON.parse(localStorage.getItem("copiedStep"));

  useEffect(() => {
    if (copiedStepLocal) {
      setCopiedStep(copiedStepLocal);
    }

    return () => {
      localStorage.removeItem("workflowRevision");
      localStorage.removeItem("selectedOption");
      setCopiedStep({});
    };
  }, []);

  window.onbeforeunload = () => {
    localStorage.removeItem("selectedOption");
  };

  useEffect(() => {
    const sortedNodes = initialNodes.sort(
      (a, b) => a.workflow_step_id - b.workflow_step_id
    );
    setSortedNodesClone(sortedNodes);
  }, [initialNodes]);

  useEffect(() => {
    document.title = `${t("decision_flow")} - Decisimo`;
    setIsLoading(true);
    if (revisionId) {
      dispatch(getWorkflowRevisionRequest({ id, revisionId }));
    } else {
      dispatch(getWorkflowRequest(id));
    }
    dispatch(getWorkflowOptionConditionTypesRequest(id));
    dispatch(getWorkflowsRequest());
    dispatch(getWorkflowStepTypesRequest());
    dispatch(getRuleSetsRequest());
    dispatch(getFunctionsRequest());
    dispatch(getModelsRequest());
    dispatch(getDecisionTablesRequest());
    dispatch(getDataSourcesRequest());
    dispatch(getScorecardsRequest());
    dispatch(fetchDecisionTreesRequest());
  }, [dispatch, revisionId]);

  useEffect(() => {
    if (
      (isGetWorkflowError && prevIsGetWorkflowError === false) ||
      (isGetWorkflowStepTypesError &&
        prevIsGetWorkflowStepTypesError === false) ||
      (isUpdatedWorkflowError && prevIsUpdatedWorkflowError === false) ||
      (isDeletedLinkError && prevIsDeletedLinkError === false) ||
      (isCreatedWorkflowReleaseError &&
        prevIsCreatedWorkflowReleaseError === false) ||
      (isCreatedWorkflowStepError &&
        prevIsCreatedWorkflowStepError === false) ||
      (isDeletedWorkflowStepError &&
        prevIsDeletedWorkflowStepError === false) ||
      (isGetWorkflowsError && prevIsGetWorkflowsError === false) ||
      (isGetWorkflowOptionConditionTypesError &&
        prevIsGetWorkflowOptionConditionTypesError === false) ||
      (isUpdatedWorkflowStepError &&
        prevIsUpdatedWorkflowStepError === false) ||
      (isGetWorkflowRevisionsError &&
        prevIsGetWorkflowRevisionsError === false) ||
      (isGetWorkflowRevisionError &&
        prevIsGetWorkflowRevisionError === false) ||
      (isCreatedWorkflowError && prevIsCreatedWorkflowError === false)
    ) {
      if (isCreatedWorkflowError) {
        setIsCreateNewRevisionOpen(false);
      }
      setIsLoading(false);
      toast.error(t("unable_process"), ToastOptions);
    }
  }, [
    isGetWorkflowError,
    isUpdatedWorkflowError,
    isDeletedLinkError,
    isCreatedWorkflowReleaseError,
    isCreatedWorkflowStepError,
    isDeletedWorkflowStepError,
    isUpdatedWorkflowStepError,
    isGetWorkflowRevisionsError,
    isGetWorkflowRevisionError,
    isCreatedWorkflowError,
  ]);

  useEffect(() => {
    if (isCreatedWorkflowSuccess && prevIsCreatedWorkflowSuccess === false) {
      const steps = structuredClone(workflowClone?.workflow_step);
      localStorage.setItem("workflowRevision", JSON.stringify(workflowClone));
      steps.map((step) => {
        step.workflow_step_id = `new_${step.workflow_step_id}`;
        step.links = [];
        step.options = [];
      });
      const data = JSON.stringify({
        steps,
        title: `Copy of ${workflowClone?.title}`,
        name: `Copy of ${workflowClone?.name}`,
        description: workflowClone?.description,
        block_deletion: workflowClone?.block_deletion,
      });

      setIsLoading(true);
      dispatch(updateWorkflowRequest({ id: eTag, data }));
    }
  }, [isCreatedWorkflowSuccess]);

  useEffect(() => {
    if (isGetWorkflowSuccess && prevIsGetWorkflowSuccess === false) {
      document.title = `${workflow.title} - ${t("decision_flow")} - Decisimo`;
      setIsLoading(false);
      if (
        isCreatedWorkflowStepSuccess &&
        Object.keys(copiedStep).length &&
        isPasted
      ) {
        const getWorkflow = structuredClone(workflow);
        const getCreated = getWorkflow.workflow_step.find(
          (step) => step.workflow_step_id === parseInt(createdStepId)
        );
        if (getCreated) {
          const getCopied = getWorkflow.workflow_step.find(
            (step) => step.workflow_step_id === copiedStep.id
          );

          let cloneOfCopied = {};
          if (getCopied) {
            cloneOfCopied = structuredClone(getCopied);
          } else {
            cloneOfCopied = copiedWorkflow.workflow_step.find(
              (step) => step.workflow_step_id === copiedStep.id
            );
          }

          if (cloneOfCopied?.links) {
            cloneOfCopied.links.map((link) => {
              link.workflow_step_id_from = getCreated.workflow_step_id;
              link.workflow_step_link_id = `new_${parseInt(
                Date.now() * Math.random()
              )}`;
            });
            getCreated.links = cloneOfCopied.links;
          }

          const data = JSON.stringify({
            steps: getWorkflow.workflow_step,
            title: getWorkflow.title,
            name: getWorkflow.name,
            description: getWorkflow.description,
            block_deletion: getWorkflow.block_deletion,
          });

          setIsLoading(true);
          setIsPasted(!isPasted);
          dispatch(updateWorkflowRequest({ id, data }));

          const typeKey = getTypeIdKey(cloneOfCopied.wf_step_type_name);
          const formData = new URLSearchParams(new FormData());
          cloneOfCopied?.options?.map((option) => {
            const optionId = `new_${Math.round(Math.random() * 10000)}`;
            formData.append("workflow_step_id", createdStepId);
            formData.append("title", cloneOfCopied.title);
            formData.append("name", cloneOfCopied.name);

            if (["external_data_id", "fork"].includes(typeKey)) {
              formData.append(
                `option[${optionId}][wf_step_option_id]`,
                optionId
              );
              formData.append(`option[${optionId}][title]`, option.title);
              formData.append(`option[${optionId}][name]`, option.name);
              if (!["fork"].includes(typeKey)) {
                formData.append(
                  `option[${optionId}][${typeKey}]`,
                  option[typeKey]
                );
              }
            } else {
              if (!["fork"].includes(typeKey)) {
                formData.append(
                  `option[${optionId}][${typeKey}]`,
                  option[typeKey]
                );
              }
              if (typeKey !== "sub_workflow_id") {
                formData.append(typeKey, option[typeKey]);
              }
            }
            option.attributes?.map((attr) => {
              const attrId = `new_${Math.round(Math.random() * 10000)}`;
              formData.append(
                `attribute[${optionId}][${attrId}][name]`,
                attr.name
              );
              formData.append(
                `attribute[${optionId}][${attrId}][vector]`,
                attr.attribute_vector
              );
              formData.append(
                `attribute[${optionId}][${attrId}][value]`,
                attr.value
              );
              if (attr.path) {
                formData.append(
                  `attribute[${optionId}][${attrId}][path]`,
                  attr.path
                );
              }
              if (attr.wf_step_opt_condition_type_id) {
                formData.append(
                  `attribute[${optionId}][${attrId}][wf_step_opt_condition_type_id]`,
                  attr.wf_step_opt_condition_type_id
                );
              }

              if (
                !["external_data_call", "fork"].includes(
                  cloneOfCopied.wf_step_type_name
                )
              ) {
                formData.append(
                  cloneOfCopied.wf_step_type_name,
                  option[typeKey]
                );
              }
            });
          });
          if (cloneOfCopied?.options) {
            if (["fork"].includes(cloneOfCopied.wf_step_type_name)) {
              formData.append(typeKey, typeKey);
            }
            dispatch(
              updateWorkflowStepRequest({
                workflowId: id,
                id: createdStepId,
                data: formData,
              })
            );
          }
        }
      }

      const prevWorkflow = localStorage.getItem("workflowRevision");
      if (eTag === id && JSON.parse(prevWorkflow)?.workflow_step?.length > 0) {
        const steps = structuredClone(workflow?.workflow_step);
        if (steps?.length > 0) {
          steps.sort(sortById);
          JSON.parse(prevWorkflow)?.workflow_step?.map((step, index) => {
            step?.links?.map((link) => {
              link.workflow_step_link_id = `new_${link.workflow_step_link_id}`;
              const linkToIndex = JSON.parse(
                prevWorkflow
              )?.workflow_step?.findIndex(
                (workflowStep) =>
                  workflowStep.workflow_step_id === link.workflow_step_id_to
              );
              const linkFromIndex = JSON.parse(
                prevWorkflow
              )?.workflow_step?.findIndex(
                (workflowStep) =>
                  workflowStep.workflow_step_id === link.workflow_step_id_from
              );

              if (linkToIndex !== -1 && linkFromIndex !== -1) {
                link.workflow_step_id_to = steps[linkToIndex].workflow_step_id;
                link.workflow_step_id_from =
                  steps[linkFromIndex].workflow_step_id;
              }

              if (steps[index]) {
                steps[index].links = step.links;
              }
            });
          });
        }

        const data = JSON.stringify({
          steps,
          title: workflow.title,
          name: workflow.name,
          description: workflow.description,
          block_deletion: workflow.block_deletion,
        });

        setIsLoading(true);
        dispatch(updateWorkflowRequest({ id: eTag, data }));
      } else if (!Object.keys(useCaseWorkflow).length) {
        const startIndex = workflow?.workflow_step
          ? workflow.workflow_step.findIndex(
              (step) => step.wf_step_type_name === "start"
            )
          : -1;
        if (!workflow?.workflow_step || startIndex === -1) {
          createStep(
            {
              name: "start",
              title: "Start",
              workflow_step_type_id: "6",
            },
            50,
            50
          );
        }
      }
    }
  }, [isGetWorkflowSuccess]);

  useEffect(() => {
    if (
      isGetWorkflowStepTypesSuccess &&
      prevIsGetWorkflowStepTypesSuccess === false
    ) {
      setIsLoading(false);
      const workflowStepTypesClone = structuredClone(workflowStepTypes);
      workflowStepTypesClone?.sort(sortByType);
      setStepTypes(workflowStepTypesClone);
    }
  }, [isGetWorkflowStepTypesSuccess]);

  useEffect(() => {
    if (
      (isUpdatedWorkflowSuccess && prevIsUpdatedWorkflowSuccess === false) ||
      (isDeletedLinkSuccess && prevIsDeletedLinkSuccess === false) ||
      (isCreatedWorkflowStepSuccess &&
        prevIsCreatedWorkflowStepSuccess === false)
    ) {
      setLinkId({});
      setIsLoading(false);
      if (isUpdatedWorkflowSuccess && revisionId) {
        setIsCreateNewRevisionOpen(false);
        toast.success(t("decision_flow_created"), ToastOptions);
        navigate(`/workflows/${eTag}`);

        const prevWorkflow = localStorage.getItem("workflowRevision");
        const steps = structuredClone(workflow.workflow_step);
        if (steps?.length > 0) {
          steps?.sort(sortById);
          JSON.parse(prevWorkflow)?.workflow_step?.map((step, index) => {
            const typeKey = getTypeIdKey(step.wf_step_type_name);
            setRevisionStepIndex(index);
            const formData = new URLSearchParams(new FormData());
            step?.options?.map((option) => {
              const optionId = `new_${Math.round(Math.random() * 10000)}`;
              formData.append(
                "workflow_step_id",
                steps[index].workflow_step_id
              );
              formData.append("title", step.title);
              formData.append("name", step.name);

              if (["external_data_id", "fork"].includes(typeKey)) {
                formData.append(
                  `option[${optionId}][wf_step_option_id]`,
                  optionId
                );
                formData.append(`option[${optionId}][title]`, option.title);
                formData.append(`option[${optionId}][name]`, option.name);
                if (!["fork"].includes(typeKey)) {
                  formData.append(
                    `option[${optionId}][${typeKey}]`,
                    option[typeKey]
                  );
                }
              } else {
                if (!["fork"].includes(typeKey)) {
                  formData.append(
                    `option[${optionId}][${typeKey}]`,
                    option[typeKey]
                  );
                }
                if (typeKey !== "sub_workflow_id") {
                  formData.append(typeKey, option[typeKey]);
                }
              }
              option.attributes?.map((attr) => {
                const attrId = `new_${Math.round(Math.random() * 10000)}`;
                formData.append(
                  `attribute[${optionId}][${attrId}][name]`,
                  attr.name
                );
                formData.append(
                  `attribute[${optionId}][${attrId}][vector]`,
                  attr.attribute_vector
                );
                formData.append(
                  `attribute[${optionId}][${attrId}][value]`,
                  attr.value
                );
                if (attr.path) {
                  formData.append(
                    `attribute[${optionId}][${attrId}][path]`,
                    attr.path
                  );
                }
                if (attr.wf_step_opt_condition_type_id) {
                  formData.append(
                    `attribute[${optionId}][${attrId}][wf_step_opt_condition_type_id]`,
                    attr.wf_step_opt_condition_type_id
                  );
                }

                if (
                  !["external_data_call", "fork"].includes(
                    step.wf_step_type_name
                  )
                ) {
                  formData.append(step.wf_step_type_name, option[typeKey]);
                }
              });
            });
            if (step?.options) {
              if (
                ["external_data_call", "fork"].includes(step.wf_step_type_name)
              ) {
                formData.append(typeKey, typeKey);
              }
              setTimeout(() => {
                dispatch(
                  updateWorkflowStepRequest({
                    workflowId: id,
                    id: step.workflow_step_id,
                    data: formData,
                  })
                );
              }, 200);
            }
          });
        }
      }
      if (!isUpdatedStep || (Object.keys(copiedStep).length && isPasted)) {
        getWorkflowDiagram();
      }

      if (isUpdatedWorkflowSuccess && Object.keys(useCaseWorkflow).length) {
        const useCaseWorkflowClone = structuredClone(useCaseWorkflow);
        const workflowStepClone = structuredClone(workflow.workflow_step);
        workflowStepClone.sort(sortByType);
        useCaseWorkflowClone.workflow_step.sort(sortByType);
        useCaseWorkflowClone.workflow_step?.map((step, index) => {
          const typeKey = getTypeIdKey(step.wf_step_type_name);
          const formData = new URLSearchParams(new FormData());
          step?.options?.map((option) => {
            const optionId = `new_${Math.round(Math.random() * 100000)}`;
            formData.append(
              "workflow_step_id",
              workflowStepClone[index]?.workflow_step_id
            );
            formData.append("title", step.title);
            formData.append("name", step.name);

            if (["external_data_id", "fork"].includes(typeKey)) {
              formData.append(
                `option[${optionId}][wf_step_option_id]`,
                optionId
              );
              formData.append(`option[${optionId}][title]`, option.title);
              formData.append(`option[${optionId}][name]`, option.name);
              if (!["fork"].includes(typeKey)) {
                formData.append(
                  `option[${optionId}][${typeKey}]`,
                  option[typeKey]
                );
              }
            } else {
              if (!["fork"].includes(typeKey)) {
                formData.append(
                  `option[${optionId}][${typeKey}]`,
                  option[typeKey]
                );
              }
              if (typeKey !== "sub_workflow_id") {
                formData.append(typeKey, option[typeKey]);
              }
            }
            option.attributes?.map((attr) => {
              const attrId = `new_${Math.round(Math.random() * 10000)}`;
              formData.append(
                `attribute[${optionId}][${attrId}][name]`,
                attr.name
              );
              formData.append(
                `attribute[${optionId}][${attrId}][vector]`,
                attr.attribute_vector
              );
              formData.append(
                `attribute[${optionId}][${attrId}][value]`,
                attr.value
              );
              if (attr.path) {
                formData.append(
                  `attribute[${optionId}][${attrId}][path]`,
                  attr.path
                );
              }
              if (attr.wf_step_opt_condition_type_id) {
                formData.append(
                  `attribute[${optionId}][${attrId}][wf_step_opt_condition_type_id]`,
                  attr.wf_step_opt_condition_type_id
                );
              }

              if (
                !["external_data_call", "fork"].includes(step.wf_step_type_name)
              ) {
                formData.append(step.wf_step_type_name, option[typeKey]);
              }
            });
          });
          if (step?.options) {
            if (
              ["external_data_call", "fork"].includes(step.wf_step_type_name)
            ) {
              formData.append(typeKey, typeKey);
            }
            dispatch(
              updateWorkflowStepRequest({
                workflowId: id,
                id: workflowStepClone[index].workflow_step_id,
                data: formData,
              })
            );
          }
        });

        dispatch(getRuleSetsRequest());
        dispatch(useCaseWorkflowRequest(null));
      }
    }
  }, [
    isUpdatedWorkflowSuccess,
    isDeletedLinkSuccess,
    isCreatedWorkflowStepSuccess,
  ]);

  useEffect(() => {
    if (
      (isGetWorkflowSuccess && prevIsGetWorkflowSuccess === false) ||
      (isGetWorkflowRevisionSuccess &&
        prevIsGetWorkflowRevisionSuccess === false)
    ) {
      setMounting(false);
    }
    if (
      !mounting &&
      (isGetWorkflowSuccess || isGetWorkflowRevisionSuccess) &&
      isGetRuleSetsSuccess &&
      isGetFunctionsSuccess &&
      isGetDecisionTablesSuccess &&
      isGetModelsSuccess &&
      isGetWorkflowsSuccess &&
      isGetDataSourcesSuccess &&
      isGetScorecardsSuccess &&
      isGetWorkflowOptionConditionTypesSuccess
    ) {
      getWorkflowDiagram();
    }
  }, [
    isGetWorkflowRevisionSuccess,
    isGetWorkflowSuccess,
    isGetRuleSetsSuccess,
    isGetFunctionsSuccess,
    isGetDecisionTablesSuccess,
    isGetModelsSuccess,
    isGetWorkflowsSuccess,
    isGetDataSourcesSuccess,
    isGetScorecardsSuccess,
    isGetWorkflowOptionConditionTypesSuccess,
    mounting,
  ]);

  useEffect(() => {
    if (isUpdatedWorkflowSuccess && prevIsUpdatedWorkflowSuccess === false) {
      getWorkflowDiagram();
      toast.success(t("workflow_saved"), ToastOptions);
    }
  }, [isUpdatedWorkflowSuccess]);

  useEffect(() => {
    if (isDeletedWorkflowSuccess && prevIsDeletedWorkflowSuccess === false) {
      setIsLoading(false);
      toast.warning(t("decision_flow_deleted"), ToastOptions);
      navigate("/workflows");
    }
  }, [isDeletedWorkflowSuccess]);

  useEffect(() => {
    if (isDeletedWorkflowError && prevIsDeletedWorkflowError === false) {
      setIsLoading(false);
      toast.error(t("not_possible_delete_decision"), ToastOptions);
    }
  }, [isDeletedWorkflowError]);

  useEffect(() => {
    if (
      isCreatedWorkflowReleaseSuccess &&
      prevIsCreatedWorkflowReleaseSuccess === false
    ) {
      setIsLoading(false);
      setReleaseId(releaseETag);
      toast.success(t("workflow_released"), ToastOptions);
      setIsReleaseModalOpen(true);
    }
  }, [isCreatedWorkflowReleaseSuccess]);

  useEffect(() => {
    if (
      isUpdatedWorkflowStepSuccess &&
      prevIsUpdatedWorkflowStepSuccess === false
    ) {
      setIsLoading(false);
      toast.success(t("step_saved"), ToastOptions);
      const prevWorkflow = localStorage.getItem("workflowRevision");
      const workflowRevisionStorage = localStorage.getItem("workflowRevision");
      if (
        revisionStepIndex === 0 ||
        revisionStepIndex ===
          JSON.parse(prevWorkflow)?.workflow_step?.length - 1 ||
        !workflowRevisionStorage
      ) {
        dispatch(getWorkflowRequest(id));
        if (workflowRevision) {
          localStorage.removeItem("workflowRevision");
        }
      }
    }
  }, [isUpdatedWorkflowStepSuccess]);

  useEffect(() => {
    if (
      isDeletedWorkflowStepSuccess &&
      prevIsDeletedWorkflowStepSuccess === false
    ) {
      toast.success(t("step_deleted"), ToastOptions);
      setIsLoading(false);
      dispatch(getWorkflowRequest(id));
    }
  }, [isDeletedWorkflowStepSuccess]);

  useEffect(() => {
    if (isDeletedLinkSuccess && prevIsDeletedLinkSuccess === false) {
      setIsLoading(false);
      toast.success(t("workflow_link_deleted"), ToastOptions);
      dispatch(getWorkflowRequest(id));
    }
  }, [isDeletedLinkSuccess]);

  useEffect(() => {
    if (
      isCreatedWorkflowStepSuccess &&
      prevIsCreatedWorkflowStepSuccess === false
    ) {
      setIsLoading(false);
      dispatch(getWorkflowRequest(id));
      toast.info(t("new_step_added"), ToastOptions);
    }
  }, [isCreatedWorkflowStepSuccess]);

  useEffect(() => {
    if (
      isGetWorkflowRevisionsSuccess &&
      prevIsGetWorkflowRevisionsSuccess === false
    ) {
      setWorkflowRevisionsData(workflowRevisions);
    }
  }, [isGetWorkflowRevisionsSuccess]);
  useEffect(() => {
    if (sortedNodesClone?.length > 0) {
      setXstepPosition(
        sortedNodesClone[sortedNodesClone.length - 1]?.position_left + 170
      );
      setYstepPosition(
        sortedNodesClone[sortedNodesClone.length - 1]?.position_top
      );
    }
  }, [sortedNodesClone]);

  useEffect(() => {
    if (xStepPosition > 1000) {
      setXstepPosition(50);
      setYstepPosition((prev) => prev + 100);
    }
  }, [xStepPosition]);

  const createStep = (type, x, y) => {
    setIsUpdatedStep(false);
    const formData = new FormData();
    formData.set("name", type.name);
    formData.set("position_left", x ? x : xStepPosition);
    formData.set("position_top", y ? y : yStepPosition);
    formData.set("title", type.title);
    formData.set("wf_step_type_name", type.name);
    formData.set("workflow_step_id", 0);
    formData.set("workflow_step_type_id", type.workflow_step_type_id);

    dispatch(createWorkflowStepRequest({ id, formData }));
  };

  const handleConfirm = () => {
    dispatch(deleteLinkRequest(linkId));
  };

  const handleShowDecisionFlowDeleteModal = () => {
    setIsDecisionFlowDeleteModalOpen(true);
  };

  const handleDecisionFlowModalClose = () => {
    setIsDecisionFlowDeleteModalOpen(false);
  };

  const handleDecisionFlowDeleteConfirm = () => {
    if (workflowName !== workflowClone.name) {
      toast.error(t("correctly_delete_decision"), ToastOptions);
    } else {
      setIsLoading(true);
      dispatch(deleteWorkflowRequest(id));
      setIsDecisionFlowDeleteModalOpen(false);
    }
  };

  const handleShowDecisionFlowEditModal = () => {
    setIsDecisionFlowEditModalOpen(true);
  };

  const handleDecisionFlowEditModalClose = () => {
    setIsDecisionFlowEditModalOpen(false);
  };

  const handleDecisionFlowEditConfirm = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const data = JSON.stringify({
      steps: workflowClone.workflow_step,
      title: e.target.title.value,
      name,
      description: e.target.description.value,
      block_deletion: e.target.block_deletion.checked,
    });

    const filteredWorkflows = workflows.filter(
      (workflow) => workflow.workflow_id !== id
    );
    if (
      !filteredWorkflows.filter((workflow) => workflow.name === name).length
    ) {
      setIsLoading(true);
      dispatch(updateWorkflowRequest({ id, data }));
      handleDecisionFlowEditModalClose();
    } else {
      toast.error(t("name_already_exists"), ToastOptions);
    }
  };

  const handleCreateRelease = () => {
    const data = new FormData();
    data.append("workflow_id", id);
    setIsLoading(true);
    //workflowRef.current.saveAsImage(); -- prepare to save the screenshot of the workflow
    dispatch(createWorkflowReleaseRequest(data));
  };

  const handleReleaseModalClose = () => {
    setReleaseId("");
    setIsReleaseModalOpen(false);
  };

  const createOption = (workflowStep, linkStepIdTo, linkId) => {
    const newOption = workflowStep.find(
      (step) => step.workflow_step_id == linkStepIdTo
    );

    if (newOption) {
      newOption.wf_step_option_id = `new_${Math.round(Math.random() * 1000)}`;
      newOption.workflow_step_link_id = linkId;
      newOption.title = `${t("branch_leading_to")}${newOption.title}`;
      return newOption;
    }
  };

  const handleShowRevisionsModal = () => {
    setIsWorkflowRevisionsModalOpen(true);
    dispatch(getWorkflowRevisionsRequest(id));
  };

  const settings = [
    {
      id: 1,
      show: !revisionId,
      content: (
        <button
          className="align-items-center d-flex dropdown-item"
          onClick={handleShowDecisionFlowEditModal}
          type="button"
        >
          <OptionsIcon className="feather feather-sliders mr-2" />{" "}
          {t("options")}
        </button>
      ),
    },
    {
      id: 2,
      content: (
        <button
          className="dropdown-item"
          onClick={handleShowRevisionsModal}
          type="button"
        >
          <ClockIcon /> {t("revisions")}
        </button>
      ),
      show: true,
      divider: revisionId ? false : workflowClone?.block_deletion !== "1",
    },
    {
      show: revisionId ? false : workflowClone?.block_deletion !== "1",
      id: 3,
      content: (
        <button
          className="align-items-center d-flex dropdown-item"
          onClick={handleShowDecisionFlowDeleteModal}
          type="button"
        >
          <DeleteIcon className="feather feather-trash-2 mr-2" /> {t("delete")}
        </button>
      ),
    },
    {
      id: 4,
      content: (
        <button
          className="dropdown-item"
          onClick={() => setIsCreateNewRevisionOpen(true)}
          type="button"
        >
          <PlusIcon /> {t("create_decision_flow")}
        </button>
      ),
      show: revisionId,
    },
  ];

  const getWorkflowDiagram = () => {
    setIsLoading(false);
    const data = structuredClone(
      revisionId ? workflowRevision?.content : workflow
    );
    setWorkflowClone(data);
    let steps = [];

    if (data?.workflow_step) {
      steps = data.workflow_step.map((step) => {
        const stepData = {
          ...step,
          name: step.title,
          color: "rgb(0, 192, 255)",
          x: step.position_left,
          y: step.position_top,
          data: {
            id: step.workflow_step_id,
            workflowId: data.workflow_id,
            title: step.title,
            name: step.name,
            type: step.wf_step_type_name,
            options: step.options === null ? [] : step.options,
          },
        };

        if (step.wf_step_type_name === "rule_set") {
          stepData.data["ruleSets"] = structuredClone(ruleSets);
        } else if (step.wf_step_type_name === "fce") {
          stepData.data["functions"] = structuredClone(functions);
        } else if (step.wf_step_type_name === "model") {
          let modelsClone = structuredClone(models);
          modelsClone = modelsClone?.filter((model) => model.status === "d");
          modelsClone?.sort(sortByTitle);
          stepData.data["models"] = modelsClone;
        } else if (step.wf_step_type_name === "decision_table") {
          stepData.data["decisionTables"] = structuredClone(decisionTables);
        } else if (step.wf_step_type_name === "scorecard") {
          stepData.data["scorecards"] = structuredClone(scorecards);
        } else if (step.wf_step_type_name === "decision_tree") {
          stepData.data["decisionTrees"] = structuredClone(decisionTrees);
        } else if (step.wf_step_type_name === "subflow") {
          let workflowsClone = structuredClone(workflows);
          workflowsClone = workflowsClone.filter(
            (workflow) => workflow.workflow_id !== id
          );
          stepData.data["subFlows"] = workflowsClone;
        } else if (step.wf_step_type_name === "external_data_call") {
          stepData.data["externalData"] = structuredClone(dataSources);
        } else if (step.wf_step_type_name === "fork") {
          if (!step.links) {
            step.links = [];
          }

          stepData.data.conditionTypes = structuredClone(
            workflowOptionConditionTypes
          );

          const workflowStepClone = structuredClone(data.workflow_step);

          // Initialize step.options as an array if null
          if (!step.options) {
            step.options = [];
          }

          // Add options for existing links if options are not defined
          if (step.links.length > 0 && step.options.length === 0) {
            step.links.forEach((link) => {
              const newOption = createOption(
                workflowStepClone,
                link.workflow_step_id_to,
                link.workflow_step_link_id
              );
              if (newOption) {
                stepData.data.options.push(newOption);
              }
            });
          } else if (step.links.length > step.options.length) {
            // Changed to 'else if'
            const missingOptions = step.links.filter(
              (link) =>
                !step.options.some(
                  (option) =>
                    option.workflow_step_link_id === link.workflow_step_link_id
                )
            );

            missingOptions.forEach((link) => {
              const newOption = createOption(
                workflowStepClone,
                link.workflow_step_id_to,
                link.workflow_step_link_id
              );
              if (newOption) {
                stepData.data.options.push(newOption);
              }
            });
          }
        }

        return stepData;
      });

      setInitialNodes(steps);
    }
  };

  const handleCloseRevisions = () => {
    setIsWorkflowRevisionsModalOpen(false);
    setWorkflowRevisionsData([]);
  };

  const handlePasteStep = () => {
    setIsPasted(true);
    const getStepCopied = copiedWorkflow.workflow_step.find(
      (step) => step.workflow_step_id === copiedStep?.id
    );
    if (getStepCopied && copiedWorkflow.workflow_id !== id) {
      createStep(getStepCopied, coordinates?.x, coordinates?.y);
    } else {
      const getStep = workflowClone.workflow_step.find(
        (step) => step.workflow_step_id === copiedStep?.id
      );
      createStep(getStep, coordinates?.x, coordinates?.y);
    }
  };

  const handleRightClick = (e) => {
    e.preventDefault();
    let rect = e.currentTarget.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    setCoordinates({
      x,
      y,
    });
  };

  const keyDownFunc = (event) => {
    if (event.ctrlKey && event.key === "v") {
      handlePasteStep();
    }
  };

  const handleLeftClick = (event) => {
    let rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setCoordinates({
      x,
      y,
    });
  };

  const handleTitleMouseEnter = () => {
    setTitleHovered(true);
  };

  const handleTitleMouseLeave = () => {
    setTitleHovered(false);
  };

  return (
    <>
      <SubHeader
        workflowMode={true}
        icon={<DecisionFlowsIcon />}
        alt={t("decision_flows")}
        title={
          <>
            <div
              onMouseEnter={handleTitleMouseEnter}
              onMouseLeave={handleTitleMouseLeave}
            >
              {revisionId && <em>{t("[revision]")}</em>}
              {workflowClone?.title}
              {titleHovered && !revisionId && (
                <>
                  <EditIcon
                    className="edit-icon ml-2"
                    onClick={handleShowDecisionFlowEditModal}
                    title={t("edit_decision_flow")}
                  />
                </>
              )}
            </div>
          </>
        }
        actions={
          <>
            <div className="d-flex mb-3 mt-2 text-right">
              {!revisionId ? (
                <span className="d-flex mr-1">
                  <button
                    className="btn outline text-success py-2"
                    title={t("release_title")}
                    onClick={handleCreateRelease}
                    data-for="release-tooltip"
                    data-tip={true}
                  >
                    <ReleaseIcon />
                  </button>
                  <ReactTooltip
                    type="dark"
                    place="left"
                    effect="solid"
                    id="release-tooltip"
                  >
                    {t("release_title")}
                  </ReactTooltip>
                </span>
              ) : null}
              {revisionId ? (
                <Link to={`/workflows/${id}`}>
                  <button className="btn outline mr-2 py-2">
                    <BackIcon />
                  </button>
                </Link>
              ) : null}
              <div>
                <button
                  title={t("decision_flow_settings")}
                  type="button"
                  className="btn primary py-2"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <SettingsIcon />
                </button>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                  {settings.length > 0 &&
                    settings
                      .filter((setting) => setting.show)
                      .map((setting) => {
                        return (
                          <span key={setting.id}>
                            {setting.content}
                            {setting.divider && (
                              <div className="dropdown-divider" />
                            )}
                          </span>
                        );
                      })}
                </div>
              </div>
            </div>
          </>
        }
      />
      <nav className="bg-white border navbar flex-nowrap justify-content-between p-2 overflow-auto">
        <div className="d-flex workflow-steps">
          {stepTypes?.length > 0 &&
            stepTypes.map((type) => {
              if (type.name !== "start") {
                return (
                  <>
                    <button
                      data-tip={true}
                      data-for={`add-step-${type.workflow_step_type_id}`}
                      title={`${t("add_step")} ${type.title}`}
                      disabled={revisionId ? "disabled" : ""}
                      className="align-items-center border btn btn-outline-secondary d-flex outline m-1 white-space-pre"
                      key={type.workflow_step_type_id}
                      onClick={() => (!revisionId ? createStep(type) : void 0)}
                    >
                      {isMobile ? (
                        <>
                          <div
                            className={`${type.name} circle ml-1 mr-1 mb-1 mt-1`}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <div className={`${type.name} circle`} />
                          {type.title}
                        </>
                      )}
                    </button>
                    <ReactTooltip
                      type="dark"
                      place="top"
                      effect="solid"
                      id={`add-step-${type.workflow_step_type_id}`}
                    >
                      {`${t("add_step")} ${type.title}`}
                    </ReactTooltip>
                  </>
                );
              }
            })}
        </div>

        <div className="d-flex">
          <button
            className="btn outline border m-1 d-flex align-items-center white-space-pre"
            onClick={() => workflowRef.current.zoomOut()}
            title={t("zoom_out")}
            data-for="zoom-out-tooltip"
            data-tip={true}
          >
            -
          </button>
          <ReactTooltip
            type="dark"
            place="top"
            effect="solid"
            id="zoom-out-tooltip"
          >
            {`${t("zoom_out")}`}
          </ReactTooltip>

          <button
            className="btn outline border m-1 d-flex align-items-center white-space-pre"
            onClick={() => workflowRef.current.zoom()}
            title={t("zoom_in")}
            data-for="zoom-in-tooltip"
            data-tip={true}
          >
            +
          </button>
          <ReactTooltip
            type="dark"
            place="top"
            effect="solid"
            id="zoom-in-tooltip"
          >
            {`${t("zoom_in")}`}
          </ReactTooltip>

          <button
            className="btn outline border m-1 d-flex align-items-center white-space-pre"
            onClick={() => workflowRef.current.zoomFit()}
            title={t("center")}
            data-for="center-tooltip"
            data-tip={true}
          >
            <CrossHairIcon />
          </button>
          <ReactTooltip
            type="dark"
            place="top"
            effect="solid"
            id="center-tooltip"
          >
            {`${t("center")}`}
          </ReactTooltip>
        </div>
      </nav>
      <div
        tabIndex={0}
        onClick={handleLeftClick}
        onKeyDown={keyDownFunc}
        onContextMenuCapture={handleRightClick}
        className={revisionId ? "revisionMode" : ""}
      >
        <WorkflowContext.Provider
          value={{
            setIsCanvasDragging,
            isCanvasDragging,
            setIsLoading,
            setIsUpdatedStep,
            setCopiedStep,
            revisionId,
            workflowClone,
            setIsEdited,
          }}
        >
          <ContextMenuTrigger
            holdToDisplay={-1}
            disable={!isCanvasDragging}
            id={
              Object.keys(copiedStep).length ? `copied-${copiedStep?.id}` : ""
            }
          >
            <Diagram
              initialNodes={initialNodes}
              workflowClone={workflowClone}
              workflowRef={workflowRef}
              setLinkId={setLinkId}
            />
            <DeleteConfirm
              handleClose={() => setLinkId("")}
              handleConfirm={handleConfirm}
              title={t("delete_flow_link")}
              message={t("do_you_want_to_delete_this_flow_link")}
              open={Object.keys(linkId).length}
            />
          </ContextMenuTrigger>
        </WorkflowContext.Provider>
      </div>
      {Object.keys(copiedStep).length && !revisionId ? (
        <ContextMenu
          id={`copied-${copiedStep?.id}`}
          preventHideOnContextMenu={true}
          hideOnLeave={true}
          preventHideOnResize={true}
          preventHideOnScroll={true}
        >
          <MenuItem onClick={handlePasteStep}>Paste</MenuItem>
        </ContextMenu>
      ) : null}
      <ReleaseWorkflow
        handleClose={handleReleaseModalClose}
        open={isReleaseModalOpen}
        releaseId={releaseId}
      />
      <DeleteConfirm
        handleClose={handleDecisionFlowModalClose}
        handleConfirm={handleDecisionFlowDeleteConfirm}
        title={t("delete_decision_flow")}
        message={
          <>
            <p>
              {t("do_you_want_to_delete_this")} <strong>{t("entire")}</strong>{" "}
              {t("decision_flow")}?
            </p>
            <p>
              {t(
                "if_you_really_want_to_you_will_need_to_write_the_name_of_the_decision_flow_below"
              )}
            </p>
            <label>
              {t("write_below")} <strong>{workflowClone?.name}</strong>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={workflowClone?.name}
              onChange={(e) => setWorkflowName(e.target.value)}
            />
          </>
        }
        open={isDecisionFlowDeleteModalOpen}
      />
      <EditWorkflow
        open={isDecisionFlowEditModalOpen}
        data={workflowClone}
        handleClose={handleDecisionFlowEditModalClose}
        handleConfirm={handleDecisionFlowEditConfirm}
      />
      <Revisions
        handleClose={handleCloseRevisions}
        revisionsData={workflowRevisionsData}
        linkTo={"workflows"}
        open={isWorkflowRevisionsModalOpen}
        elemId={parseInt(id)}
      />
      <CreateNewItem
        handleClose={() => setIsCreateNewRevisionOpen(false)}
        handleConfirm={() => createWorkflowCopy(workflowClone, dispatch)}
        title={t("decision_flow")}
        open={isCreateNewRevisionOpen}
      />
    </>
  );
};

export default UpdateWorkflow;
