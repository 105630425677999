import React, { useContext, useEffect, useState } from "react";
import { updateUserAccountPasswordRequest } from "redux/user/action";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import usePrevious from "utility/hooks/usePrevious";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as XIcon } from "assets/icons/error.svg";
import PasswordStrengthIndicator from "components/user/PasswordStrengthIndicator";

const ChangePasswordModal = ({ userId, open, handleClose }) => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isChangeAccountPasswordSuccess, isChangeAccountPasswordFailure } =
    useSelector((state) => {
      return state.users;
    });

  const prevIsChangeAccountPasswordSuccess = usePrevious(
    isChangeAccountPasswordSuccess
  );

  const prevIsChangeAccountPasswordFailure = usePrevious(
    isChangeAccountPasswordFailure
  );

  const [passwordType, setPasswordType] = useState("password");

  const [requirements, setRequirements] = useState({
    length: false,
    uppercaseLowercase: false,
    number: false,
    specialChar: false,
  });

  const [hasInteracted, setHasInteracted] = useState(false);

  const validatePassword = (password) => {
    setRequirements({
      length: password.length >= 12,
      uppercaseLowercase: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const handlePasswordChange = (e) => {
    validatePassword(e.target.value);
  };

  const getRequirementColor = (requirement) => {
    if (!hasInteracted) return "#6c757d"; // neutral gray
    return requirement ? "#28a745" : "#dc3545"; // green if met, red if not
  };

  const getRequirementIcon = (requirement) => {
    if (!hasInteracted) return null;
    return requirement ? <CheckIcon /> : <XIcon />;
  };

  const handlePasswordBlur = () => {
    setHasInteracted(true);
  };

  useEffect(() => {
    if (open) {
      // Reset states when modal opens
      setRequirements({
        length: false,
        uppercaseLowercase: false,
        number: false,
        specialChar: false,
      });
      setHasInteracted(false);
      setPasswordType("password");
      // Reset form if it exists
      document.getElementById("changePasswordForm")?.reset();
    }
  }, [open]);

  useEffect(() => {
    if (
      isChangeAccountPasswordSuccess &&
      prevIsChangeAccountPasswordSuccess === false
    ) {
      setIsLoading(false);
      handleClose();
    }
  }, [isChangeAccountPasswordSuccess]);

  useEffect(() => {
    if (
      isChangeAccountPasswordFailure &&
      prevIsChangeAccountPasswordFailure === false
    ) {
      setIsLoading(false);
    }
  }, [isChangeAccountPasswordFailure]);

  const handleConfirm = (e) => {
    e.preventDefault();
    const data = new URLSearchParams();
    data.append("password", e.target.new_password.value);
    data.append("user_account_id", userId);
    setIsLoading(true);
    dispatch(updateUserAccountPasswordRequest(data));
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // Modify handleClose to include cleanup
  const onClose = () => {
    setRequirements({
      length: false,
      uppercaseLowercase: false,
      number: false,
      specialChar: false,
    });
    setHasInteracted(false);
    setPasswordType("password");
    handleClose();
  };

  return (
    <>
      <Modal show={open} onHide={onClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("change_user_password")}</h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0">{t("password_requirements")}</p>
          <ul className="list-unstyled ml-2 mt-0 pt-0 small">
            <li
              id="requirement_password_length"
              style={{
                color: getRequirementColor(requirements.length),
                marginBottom: "0",
              }}
            >
              {getRequirementIcon(requirements.length)} {t("password_length")}
            </li>
            <li
              id="requirement_password_uppercase_lowercase"
              style={{
                color: getRequirementColor(requirements.uppercaseLowercase),
                marginBottom: "0",
              }}
            >
              {getRequirementIcon(requirements.uppercaseLowercase)}{" "}
              {t("password_uppercase_lowercase")}
            </li>
            <li
              id="requirement_password_number"
              style={{
                color: getRequirementColor(requirements.number),
                marginBottom: "0",
              }}
            >
              {getRequirementIcon(requirements.number)} {t("password_number")}
            </li>
            <li
              id="requirement_password_special_characters"
              style={{
                color: getRequirementColor(requirements.specialChar),
                marginBottom: "0",
              }}
            >
              {getRequirementIcon(requirements.specialChar)}{" "}
              {t("password_special_characters")}
            </li>
          </ul>
          <form id="changePasswordForm" onSubmit={(e) => handleConfirm(e)}>
            <label>{t("new_password")}</label>
            <div className="input-group mb-3">
              <input
                className="form-control"
                name="new_password"
                type={passwordType}
                required
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
              />
              <div className="input-group-append">
                <button
                  className="btn outline"
                  type="button"
                  onClick={togglePassword}
                >
                  {passwordType === "password" ? <EyeIcon /> : <EyeOffIcon />}
                </button>
              </div>
            </div>
            <div className="mb-3">
              <PasswordStrengthIndicator requirements={requirements} t={t} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="outline" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button
            variant=""
            className="primary"
            form="changePasswordForm"
            type="submit"
          >
            {t("confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ChangePasswordModal.propTypes = {
  userId: PropTypes.number,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ChangePasswordModal;
