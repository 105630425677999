// src/utils/types.js

export const ItemType = {
  ATTRIBUTE: "a",
  OPERATOR: "o",
  CONSTANT: "c",
  VALUE: "v",
  BRACKET: "b",
  FUNCTION: "f",
};

export const ComparisonType = {
  UNCHANGED: "unchanged",
  ADDED: "added",
  DELETED: "deleted",
  MODIFIED: "modified",
};

export class FormulaNode {
  constructor(id, type, value, children = []) {
    this.id = id; // Unique identifier
    this.type = type; // Type of the node (from ItemType)
    this.value = value; // Operator, function name, attribute name, etc.
    this.children = children; // Child nodes (for functions and brackets)
  }
}
