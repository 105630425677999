import { createFunctionRequest } from "../../redux/functions/action";

export const style = {
  width: "228px",
  height: "35px",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  border: "1px solid",
  borderColor: "rgba(118, 118, 118, 0.3)",
};

export const operatorStyle = {
  width: "32px",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow:
    "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px",
};

export const renderFormula = (items, res = "", attributes = []) => {
  items.forEach((item) => {
    if (item.item_type === "v") {
      res += item.numeric_value;
    } else if (["o", "c"].includes(item.item_type)) {
      res += item.operator === "rand" ? '("rand")' : item.operator;
    } else if (item.item_type === "a") {
      if (attributes?.length > 0) {
        const attribute = attributes.find(
          (attr) => attr.fce_attribute_id == item.fce_attribute_id
        );
        res += `"${attribute ? attribute.name : ""}"`;
      } else {
        res += `{}`;
      }
    } else if (["b", "f"].includes(item.item_type)) {
      if (item.item_type === "f") {
        res += item.operator;
      }
      res += "(";
      res = renderFormula(item.children, res, attributes);
      res += ")";
    }
  });

  return res;
};

export const handleLayers = (data, finalData, parentId = null) => {
  data.forEach((item) => {
    const newItem = {
      ...item,
      parent_fce_item_id: parentId,
    };
    finalData.push(newItem);
    if (["b", "f"].includes(item.item_type)) {
      handleLayers(newItem.children, finalData, newItem.fce_item_id);
      finalData.push({
        ...newItem,
        item_type: `${item.item_type}_c`,
        parent_fce_item_id: parentId,
        fce_item_id: `new_${Math.round(Math.random() * 10000)}`,
        bracket_end: newItem.fce_item_id,
      });
    }
  });
};

export const createItemCopy = (content, dispatch, templateMode = false) => {
  const functionContent = structuredClone(content);
  const data = new URLSearchParams(new FormData());

  data.set(
    "title",
    !templateMode
      ? `Copy of ${functionContent.info?.title}`
      : functionContent.info?.title
  );
  data.set(
    "name",
    !templateMode
      ? `copy-${functionContent.info?.name}`
      : functionContent.info?.name
  );
  data.set("x_attribute_path", functionContent.info?.x_attribute_path);

  if (functionContent.attributes?.length > 0) {
    functionContent.attributes?.forEach((attribute) => {
      data.set(
        `fce_attribute[new_${attribute.fce_attribute_id}][name]`,
        attribute.name
      );
      data.set(
        `fce_attribute[new_${attribute.fce_attribute_id}][fce_attribute_id]`,
        `new_${attribute.fce_attribute_id}`
      );
      data.set(
        `fce_attribute[new_${attribute.fce_attribute_id}][attribute_path]`,
        attribute.attribute_path
      );
    });
  }

  if (functionContent.items?.length > 0) {
    functionContent.items?.forEach((item) => {
      data.set(
        `fce_itm[new_${item.fce_item_id}][parent_fce_item_id]`,
        item.parent_fce_item_id ? `new_${item.parent_fce_item_id}` : null
      );
      data.set(`fce_itm[new_${item.fce_item_id}][item_type]`, item.item_type);
      if (["o", "c", "f"].includes(item.item_type)) {
        data.set(`fce_itm[new_${item.fce_item_id}][operand]`, item.operator);
      } else if (item.item_type === "v") {
        data.set(
          `fce_itm[new_${item.fce_item_id}][numeric_value]`,
          item.numeric_value
        );
      } else if (item.item_type === "a") {
        data.set(
          `fce_itm[new_${item.fce_item_id}][fce_attribute_id]`,
          `new_${item.fce_attribute_id}`
        );
      }
      data.set(
        `fce_itm[new_${item.fce_item_id}][fce_item_id]`,
        `new_${item.fce_item_id}`
      );
    });
  }

  setTimeout(() => {
    dispatch(createFunctionRequest(data));
  }, 1000);
};
