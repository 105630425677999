import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { useTranslation } from "react-i18next";

const Revisions = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    open,
    handleClose,
    revisionsData,
    elemId,
    linkTo,
    leftRevisionId,
    rightRevisionId,
  } = props;
  const [selectedRevisions, setSelectedRevisions] = useState([]);
  const [leftRevision, setLeftRevision] = useState(null);
  const [rightRevision, setRightRevision] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const maxRevisions = 10;
  const totalRevisions = revisionsData?.length || 0;
  const visibleRevisions = showAll
    ? revisionsData
    : revisionsData.slice(-maxRevisions);

  useEffect(() => {
    if (revisionsData && revisionsData.length >= 2) {
      // Get last and second-to-last revisions
      const lastRevision = revisionsData[revisionsData.length - 1];
      const secondToLastRevision = revisionsData[revisionsData.length - 2];

      // Set initial selections
      setLeftRevision(secondToLastRevision.revision_id);
      setRightRevision(lastRevision.revision_id);
    }
  }, [revisionsData]);

  useEffect(() => {
    if (revisionsData?.length >= 2) {
      if (leftRevisionId && rightRevisionId) {
        // Use pre-selected revisions if provided
        setLeftRevision(leftRevisionId);
        setRightRevision(rightRevisionId);
      } else {
        // Default to last two revisions
        const lastRevision = revisionsData[revisionsData.length - 1];
        const secondToLastRevision = revisionsData[revisionsData.length - 2];
        setLeftRevision(secondToLastRevision.revision_id);
        setRightRevision(lastRevision.revision_id);
      }
    }
  }, [revisionsData, leftRevisionId, rightRevisionId]);

  const handleCompare = () => {
    navigate(
      `/${linkTo}/${elemId}/revisions/${leftRevision}/compare/${rightRevision}`
    );
    handleClose();
  };

  const navigateToRevision = (revisionId) => {
    navigate(`/${linkTo}/${elemId}/revisions/${revisionId}`);
    handleClose();
  };

  return (
    <>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("revision")}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th scope="col" style={{ width: "5%" }} colSpan={2}>
                  {t("compare")}
                </th>
                <th scope="col">{t("revision")}</th>
                <th scope="col">{t("time")}</th>
                <th scope="col">{t("revisions_user")}</th>
                <th scope="col">{t("view")}</th>
              </tr>
            </thead>
            <tbody id="revisions-list">
              {totalRevisions > maxRevisions && (
                <tr>
                  <td colSpan="6">
                    <div className="d-flex justify-content-center add-row-bg p-1 m-0">
                      {!showAll && (
                        <button
                          onClick={() => setShowAll(true)}
                          type="button"
                          className="btn outline m-0"
                        >
                          {t("show_previous_x_revisions", {
                            count: totalRevisions - maxRevisions,
                          })}
                        </button>
                      )}
                      {showAll && (
                        <button
                          onClick={() => setShowAll(false)}
                          type="button"
                          className="btn outline m-0"
                        >
                          {t("show_only_last_x_revisions", {
                            count: maxRevisions,
                          })}
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              )}

              {visibleRevisions.map((revision) => {
                return (
                  <tr key={revision.revision_index}>
                    <td>
                      <input
                        type="radio"
                        name="leftRevision"
                        checked={leftRevision === revision.revision_id}
                        onChange={() => setLeftRevision(revision.revision_id)}
                        disabled={rightRevision === revision.revision_id}
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name="rightRevision"
                        checked={rightRevision === revision.revision_id}
                        onChange={() => setRightRevision(revision.revision_id)}
                        disabled={leftRevision === revision.revision_id}
                      />
                    </td>
                    <th scope="row">{revision.revision_index}</th>
                    <td>{revision.dtime_inserted}</td>
                    <td>{revision.full_name}</td>
                    <td>
                      <button
                        title={t("revision")}
                        className="bg-transparent border-0 primary-text"
                        onClick={() => navigateToRevision(revision.revision_id)}
                      >
                        <EyeIcon />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button
            className="primary"
            variant=""
            type="button"
            disabled={!leftRevision || !rightRevision}
            onClick={handleCompare}
          >
            {t("compare")}
          </Button>
          <Button variant="" className="outline" onClick={handleClose}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Revisions.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  revisionsData: PropTypes.array,
  elemId: PropTypes.number.isRequired,
  linkTo: PropTypes.string.isRequired,
  leftRevisionId: PropTypes.number, // Optional
  rightRevisionId: PropTypes.number, // Optional
};

export default Revisions;
