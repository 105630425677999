// src/utils/utils.js

import { FormulaNode, ItemType, ComparisonType } from "./types";

/**
 * Builds a tree structure from the items array.
 * @param {Array} items - Array of formula items.
 * @param {Array} attributes - Array of attributes.
 * @returns {Array} - Array of FormulaNode representing the tree.
 */
export const buildFormulaTree = (items, attributes) => {
  const attributeMap = attributes.reduce((acc, attr) => {
    acc[attr.fce_attribute_id] = attr.name;
    return acc;
  }, {});

  // Sort items based on order_index
  const sortedItems = [...items].sort(
    (a, b) => parseInt(a.order_index) - parseInt(b.order_index)
  );

  const idToNodeMap = {};
  const roots = [];

  sortedItems.forEach((item) => {
    const {
      fce_item_id,
      item_type,
      operator,
      numeric_value,
      parent_fce_item_id,
    } = item;
    let value = "";

    switch (item_type) {
      case ItemType.ATTRIBUTE:
        value = attributeMap[item.fce_attribute_id] || "unknown";
        break;
      case ItemType.OPERATOR:
      case ItemType.CONSTANT:
        value = operator;
        break;
      case ItemType.VALUE:
        value = numeric_value;
        break;
      case ItemType.BRACKET:
        value = operator || ""; // Assuming operator holds bracket type if any
        break;
      case ItemType.FUNCTION:
        value = operator; // Function name
        break;
      default:
        value = "";
    }

    const node = new FormulaNode(fce_item_id, item_type, value);

    idToNodeMap[fce_item_id] = node;

    if (parent_fce_item_id) {
      const parentNode = idToNodeMap[parent_fce_item_id];
      if (parentNode) {
        parentNode.children.push(node);
      } else {
        // Handle cases where parent is not found
        roots.push(node);
      }
    } else {
      roots.push(node);
    }
  });

  return roots;
};

/**
 * Traverses the formula tree to build the formula string.
 * @param {Array} tree - Array of FormulaNode (roots).
 * @returns {string} - Formula string.
 */
export const traverseFormulaTree = (tree) => {
  const traverse = (node) => {
    let formula = "";

    switch (node.type) {
      case ItemType.FUNCTION:
        formula += `${node.value}(`;
        node.children.forEach((child) => {
          formula += traverse(child);
        });
        formula += ")";
        break;
      case ItemType.ATTRIBUTE:
      case ItemType.CONSTANT:
      case ItemType.OPERATOR:
      case ItemType.VALUE:
        formula += node.value;
        break;
      case ItemType.BRACKET:
        formula += "(";
        node.children.forEach((child) => {
          formula += traverse(child);
        });
        formula += ")";
        break;
      default:
        break;
    }

    return formula;
  };

  return tree.map((node) => traverse(node)).join(" ");
};

/**
 * Compares two formula strings and returns an array of comparison objects.
 * @param {string} formula1 - First formula string.
 * @param {string} formula2 - Second formula string.
 * @returns {Array} - Array of comparison objects.
 */
export const compareFormulas = (formula1, formula2) => {
  const parseFormula = (formula) => {
    if (!formula) return [];

    // Updated regex to include underscores and Unicode letters
    const regex = /([^+\-*/^%(),\s]+)|([+\-*/^%(),])|(\d+\.\d+|\d+)/g;
    let tokens = [];
    let match;
    while ((match = regex.exec(formula)) !== null) {
      if (match[1]) {
        // Functions or variables with underscores and Unicode characters
        tokens.push(match[1]);
      } else if (match[2]) {
        // Operators and parentheses
        tokens.push(match[2]);
      } else if (match[3]) {
        // Numbers
        tokens.push(match[3]);
      }
    }
    return tokens;
  };

  const normalizeToken = (token) => {
    // Always return a string to prevent TypeErrors
    return token.toString().trim();
  };

  const traverseAndCompare = (tokens1, tokens2) => {
    const maxLength = Math.max(tokens1.length, tokens2.length);
    const differences = [];

    for (let i = 0; i < maxLength; i++) {
      const token1 = normalizeToken(tokens1[i] || "");
      const token2 = normalizeToken(tokens2[i] || "");

      if (token1 === token2) {
        differences.push({ type: ComparisonType.UNCHANGED, value: token1 });
      } else if (!tokens1[i]) {
        differences.push({ type: ComparisonType.ADDED, value: token2 });
      } else if (!tokens2[i]) {
        differences.push({ type: ComparisonType.DELETED, value: token1 });
      } else {
        differences.push({
          type: ComparisonType.MODIFIED,
          oldValue: token1,
          newValue: token2,
        });
      }
    }

    return differences;
  };

  const tokens1 = parseFormula(formula1);
  const tokens2 = parseFormula(formula2);

  return traverseAndCompare(tokens1, tokens2);
};
