import React from "react";
import PropTypes from "prop-types";

const PasswordStrengthIndicator = ({ requirements, t }) => {
  const getStrengthCount = () => {
    return Object.values(requirements).filter(Boolean).length;
  };

  const getStrengthColor = (index) => {
    const strength = getStrengthCount();
    if (strength === 4) return "#28a745"; // green when all complete
    if (index < strength) return "#ffa500"; // orange for partial progress
    return "#dee2e6"; // gray for unfilled
  };

  return (
    <>
      <span className="small text-muted">{t("password_strength")}</span>
      <div className="d-flex gap-2 mb-2">
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            style={{
              width: "100%",
              height: "4px",
              backgroundColor: getStrengthColor(index),
              transition: "background-color 0.3s ease",
            }}
          />
        ))}
      </div>
    </>
  );
};

PasswordStrengthIndicator.propTypes = {
  requirements: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default PasswordStrengthIndicator;
