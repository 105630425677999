import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { mode } from "mathjs";

const UploadModelConfig = ({
  t,
  modelType,
  setModelType,
  containerImages,
  containerPlugins,
  handleSelectFile,
  selectedFile,
}) => {
  const [containerImagesClone, setContainerImagesClone] = useState([]);
  const [containerPluginsClone, setContainerPluginsClone] = useState([]);
  const [functionName, setFunctionName] = useState("predict_proba");

  let firstRender = true;

  useEffect(() => {
    setContainerImagesClone(
      containerImages.filter((img) => img.model_type === modelType)
    );
    setContainerPluginsClone(
      containerPlugins.filter((plugin) => plugin.model_type === modelType)
    );
  }, [modelType, containerImages, containerPlugins]);

  useEffect(() => {
    if (firstRender && containerImages.length > 0) {
      if (!modelType) {
        setModelType(containerImages[0].model_type);
      }
    }
  }, [modelType, containerImages]);

  return (
    <div className="card border-0 shadow-sm">
      <div className="card-body">
        <div className="row">
          <div className="col-lg col-12 pt-2">
            <label>{t("model_type")}</label>
            <select
              className="form-control"
              name="model_type"
              onChange={(e) => setModelType(e.target.value)}
            >
              <option value="python">Python</option>
              <option value="julia">Julia</option>
              <option value="r">R</option>
            </select>
          </div>
          <div className="col-lg col-12 pt-2">
            <label>
              {modelType === "python"
                ? t("python_version")
                : modelType === "julia"
                ? t("julia_version")
                : modelType === "r"
                ? t("r_version")
                : t("python_version")}
            </label>
            <select name="model_container_image_id" className="form-control">
              {containerImagesClone?.map((img) => (
                <option
                  key={img.model_container_image_id}
                  value={img.model_container_image_id}
                >
                  {img.title}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg col-12 pt-2">
            <label>{t("execution_function")}</label>
            <select
              name="function_name"
              className="form-control"
              onChange={(e) => setFunctionName(e.target.value)}
            >
              <option value="predict_proba">predict_proba()</option>
              <option value="predict">predict()</option>
            </select>
          </div>
          <div className="col-lg col-12 pt-2">
            <label>{t("select_model_file")}</label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="pickle-file"
                name="model"
                accept=".pkl, .pickle, .rdata, .r, .rds"
                onChange={(e) => handleSelectFile(e.target.files[0])}
              />
              <label
                className={`custom-file-label ${
                  selectedFile ? "border-success text-success" : ""
                }`}
                htmlFor="csv-file"
              >
                {selectedFile ? selectedFile.name : t("choose_pickle_file")}
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <label>{t("select_python")}</label>
            <div className="btn-group-toggle" data-toggle="buttons">
              {containerPluginsClone?.map((plugin) => (
                <label
                  key={plugin.model_container_plugin_id}
                  className="btn outline mr-1"
                >
                  <input type="checkbox" name="plugin[]" value={plugin.name} />
                  {plugin.name}
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UploadModelConfig.propTypes = {
  t: PropTypes.func.isRequired,
  modelType: PropTypes.string,
  setModelType: PropTypes.func.isRequired,
  containerImages: PropTypes.array.isRequired,
  containerPlugins: PropTypes.array.isRequired,
  handleSelectFile: PropTypes.func.isRequired,
  selectedFile: PropTypes.object,
};

export default UploadModelConfig;
