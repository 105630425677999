// ValidationSets.js
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteValidationSetRequest,
  getValidationSetsRequest,
} from "redux/releases/action";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import SubHeader from "components/SubHeader";
import DeleteConfirm from "components/modals/DeleteConfirm";
import usePrevious from "utility/hooks/usePrevious";
import { MainContext } from "context/contexts";
import { Pagination, sortByTimeDesc, TableNoItems } from "utility/utility";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ValidationSets = () => {
  const { setIsLoading, isMobile } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isGetValidationSetsSuccess,
    validationSets,
    isDeletedValidationSetSuccess,
    isGetValidationSetsError,
    isDeletedValidationSetError,
  } = useSelector((state) => state.releases);

  const prevIsDeletedValidationSetSuccess = usePrevious(
    isDeletedValidationSetSuccess
  );
  const prevIsGetValidationSetsSuccess = usePrevious(
    isGetValidationSetsSuccess
  );
  const prevIsGetValidationSetsError = usePrevious(isGetValidationSetsError);
  const prevIsDeletedValidationSetError = usePrevious(
    isDeletedValidationSetError
  );

  const [setsClone, setSetsClone] = useState([]);
  const [selectedSet, setSelectedSet] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [isSetDeleteModalOpen, setIsSetDeleteModalOpen] = useState(false);

  useEffect(() => {
    document.title = `${t("validation_sets")} - Decisimo`;
  }, [t]);

  useEffect(() => {
    if (currentPage >= 0) {
      setIsLoading(true);
      dispatch(getValidationSetsRequest(currentPage));
    }
  }, [currentPage, dispatch, setIsLoading]);

  useEffect(() => {
    if (
      isGetValidationSetsSuccess &&
      prevIsGetValidationSetsSuccess === false
    ) {
      setIsLoading(false);
      const sortedSets = structuredClone(validationSets);

      setSetsClone(sortedSets);
    }
  }, [
    isGetValidationSetsSuccess,
    validationSets,
    prevIsGetValidationSetsSuccess,
    setIsLoading,
  ]);

  // Handle validation sets loading errors
  useEffect(() => {
    if (isGetValidationSetsError && prevIsGetValidationSetsError === false) {
      setIsLoading(false);
      toast.error(t("error_loading_validation_sets"), ToastOptions);
    }
  }, [isGetValidationSetsError, prevIsGetValidationSetsError, t]);

  // Handle validation set deletion errors
  useEffect(() => {
    if (
      isDeletedValidationSetError &&
      prevIsDeletedValidationSetError === false
    ) {
      setIsLoading(false);
      toast.error(t("error_deleting_validation_set"), ToastOptions);
    }
  }, [isDeletedValidationSetError, prevIsDeletedValidationSetError, t]);

  useEffect(() => {
    if (
      isDeletedValidationSetSuccess &&
      prevIsDeletedValidationSetSuccess === false
    ) {
      setIsLoading(false);
      setSetsClone((prevSets) =>
        prevSets.filter(
          (set) =>
            set.release_validation_set_id !==
            selectedSet.release_validation_set_id
        )
      );
      handleClose();
      toast.warning(t("validation_set_deleted"), ToastOptions);
    }
  }, [
    isDeletedValidationSetSuccess,
    prevIsDeletedValidationSetSuccess,
    selectedSet.release_validation_set_id,
    t,
    setIsLoading,
  ]);

  const handleShowDeleteModal = (set) => {
    setSelectedSet(set);
    setIsSetDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsSetDeleteModalOpen(false);
    setSelectedSet({});
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(
      deleteValidationSetRequest({
        validationSetId: selectedSet.release_validation_set_id,
      })
    );
    setIsSetDeleteModalOpen(false);
  };

  return (
    <>
      <SubHeader title={t("validation_sets")} actions="" />
      <div className="mb-3">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="col-lg-8 py-3">
                {t("name")}
              </th>
              {!isMobile && (
                <th scope="col" className="col-lg-3 py-3">
                  {t("last_modified")}
                </th>
              )}
              <th scope="col" className="col-lg-2 py-3" />
            </tr>
          </thead>
          <tbody className="py-4">
            {setsClone?.length > 0 ? (
              setsClone.map((set) => (
                <tr key={set.release_validation_set_id} className="td-flow">
                  <td> {set.original_file_name}</td>
                  {!isMobile && <td>{set.dtime_modified}</td>}
                  <td className="d-flex align-items-center justify-content-end border-0">
                    <button
                      title={t("delete_validation_set")}
                      type="button"
                      className="btn primary-text border-0 p-0 pb-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <MoreVertical style={{ cursor: "pointer" }} />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleShowDeleteModal(set)}
                      >
                        <TrashIcon /> {t("delete")}
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : isGetValidationSetsSuccess && validationSets?.length === 0 ? (
              <TableNoItems colspan={5} />
            ) : null}
          </tbody>
        </table>
      </div>
      <div className="pagination justify-content-end mb-5">
        <Pagination
          currentPage={currentPage}
          itemsLength={validationSets?.length || 0}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_validation_set")}
        message={
          <span>
            {t("do_you_want_to_delete")} <b>{selectedSet.original_file_name}</b>
            ?
          </span>
        }
        open={isSetDeleteModalOpen}
      />
    </>
  );
};

export default ValidationSets;
