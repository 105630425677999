import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagesRequest, signUpRequest } from "redux/auth/action";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import { Card, CardsBottomSide } from "components/auth/Card";
import "components/auth/register.scss";
import { emailRegex } from "utility/utility";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as XIcon } from "assets/icons/error.svg";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const maxPasswordLength = 64;
const minPasswordLength = 12;

const isPasswordValid = (requirements) => {
  return Object.values(requirements).every((req) => req === true);
};

const validateRegistration = (
  fullName,
  email,
  password,
  agreeTerms,
  passwordRequirements
) => {
  if (fullName.length < 3) {
    return { isValid: false, error: "fill_full_name" };
  }

  if (!email || !email.match(emailRegex)) {
    return { isValid: false, error: "invalid_email_address" };
  }

  if (
    !password ||
    password.length <= minPasswordLength ||
    password.length >= maxPasswordLength
  ) {
    return { isValid: false, error: "password_length" };
  }

  if (!isPasswordValid(passwordRequirements)) {
    return { isValid: false, error: "password_requirements_not_met" };
  }

  if (!agreeTerms) {
    return { isValid: false, error: "agree_terms" };
  }

  return { isValid: true };
};

const Register = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isSignUpRequest,
    isSignUpSuccess,
    isSignUpFailure,
    statusCode,
    authenticationError,
    languages,
  } = useSelector((state) => state.auth);
  const prevIsSignUpSuccess = usePrevious(isSignUpSuccess);
  const prevIsSignUpFailure = usePrevious(isSignUpFailure);
  const [secret, setSecret] = useState(0);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [requirements, setRequirements] = useState({
    length: null,
    uppercaseLowercase: null,
    number: null,
    specialChar: null,
  });

  const [selectedLanguage, setSelectedLanguage] = useState(1);

  useEffect(() => {
    document.title = "Sign up - Decisimo";
  }, []);

  useEffect(() => {
    if (isSignUpSuccess && prevIsSignUpSuccess === false) {
      toast.success(t("joining_us"), ToastOptions);
    }
  }, [isSignUpSuccess, prevIsSignUpSuccess]);

  useEffect(() => {
    if (
      isSignUpFailure &&
      prevIsSignUpFailure === false &&
      statusCode !== 405
    ) {
      //clean up toasts
      toast.dismiss();
      if (authenticationError?.error?.code) {
        toast.error(t("error_" + authenticationError.error.code), ToastOptions);
      } else {
        toast.error(t("unable_sign_up"), ToastOptions);
      }
    }
  }, [isSignUpFailure]);

  useEffect(() => {
    dispatch(getLanguagesRequest());
  }, []);

  const onHandleRegister = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const fullName = e.target.full_name.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    const agreeTerms = e.target.agree_terms.checked;
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const selectedLanguageCode = languages.find(
      (item) => item.language_id === e.target.language_id.value
    );
    localStorage.setItem("user_language", selectedLanguageCode?.code);

    data.append("time_zone", time_zone);

    const validation = validateRegistration(
      fullName,
      email,
      password,
      agreeTerms,
      requirements
    );
    if (!validation.isValid) {
      return toast.error(t(validation.error), ToastOptions);
    }

    dispatch(signUpRequest(data));
  };

  const togglePassword = () => {
    if (secret === 1) {
      setSecret(0);
      return;
    }
    setSecret(1);
  };

  const handleChangeLanguage = (e) => {
    const selectedLanguage = languages.find((item) => item.language_id === e);
    i18n.changeLanguage(selectedLanguage.code);
  };

  const validatePassword = (password) => {
    setRequirements({
      length: password.length >= 12,
      uppercase_lowercase: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
      number: /\d/.test(password),
      special_characters: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const getRequirementColor = (requirement) => {
    if (!hasInteracted) return "#6c757d"; // neutral gray
    return requirement ? "#28a745" : "#dc3545"; // green if met, red if not
  };

  const getRequirementIcon = (requirement) => {
    if (!hasInteracted || requirement === null) return null;
    return requirement ? <CheckIcon /> : <XIcon />;
  };

  const handlePasswordBlur = () => {
    setHasInteracted(true);
  };

  return (
    <>
      <Card>
        <form className="form-pretty" onSubmit={onHandleRegister}>
          <h1 className="form-pretty__title mt-4 mb-4">{t("set_up")}</h1>
          <div>
            <label className="sr-only">{t("full_name")}</label>
            <input
              type="text"
              name="full_name"
              className="form-control mb-2"
              placeholder={t("full_name")}
              required
            />
            <label htmlFor="inputEmail" className="sr-only mt-2">
              {t("work_email")}
            </label>
            <input
              type="email"
              name="email"
              className="form-control mb-2"
              placeholder={t("work_email")}
              required
              autoFocus
            />
            <div className="password-section">
              <label>{t("password")}</label>
              <div className="input-group mb-3">
                <input
                  type={secret ? "text" : "password"}
                  name="password"
                  className="form-control"
                  placeholder={t("password")}
                  required
                  onChange={(e) => validatePassword(e.target.value)}
                  onBlur={handlePasswordBlur}
                />
                <div className="input-group-append">
                  <button
                    className="btn outline"
                    type="button"
                    onClick={togglePassword}
                  >
                    {secret ? <EyeOffIcon /> : <EyeIcon />}
                  </button>
                </div>
              </div>

              <div className="requirements mt-2">
                <p>{t("password_requirements")}</p>
                <ul className="list-unstyled">
                  {[
                    "length",
                    "uppercase_lowercase",
                    "number",
                    "special_characters",
                  ].map((key) => (
                    <li
                      key={key}
                      style={{ color: getRequirementColor(requirements[key]) }}
                    >
                      {getRequirementIcon(requirements[key])}{" "}
                      {t(`password_${key}`)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <select
              name="language_id"
              className="form-control mb-2"
              onChange={(e) => {
                handleChangeLanguage(e.target.value);
                setSelectedLanguage(Number(e.target.value));
              }}
              value={selectedLanguage}
            >
              {languages?.length > 0 &&
                languages.map((lang) => (
                  <option key={lang.language_id} value={lang.language_id}>
                    {lang.title}
                  </option>
                ))}
            </select>
            <div className="d-flex align-start">
              <input
                type="checkbox"
                name="agree_terms"
                value="1"
                id="terms"
                required
              />
              <label className="signup-checkbox mb-0 ml-2" htmlFor="terms">
                {t("agree_with")}
                <a
                  href="https://www.decisimo.com/terms_portal.html"
                  target="_blank"
                  rel="noreferrer"
                  className="ml-1"
                  style={{ textDecoration: "underline" }}
                >
                  {t("terms_of_use")}
                </a>
              </label>
            </div>
            <button
              className="form-pretty__btn w-170"
              type="submit"
              disabled={isSignUpRequest}
            >
              {isSignUpRequest ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">{t("loading")}</span>
                </div>
              ) : (
                t("sign_up")
              )}
            </button>
            <hr />
          </div>
          <CardsBottomSide />
        </form>
      </Card>
    </>
  );
};

export default Register;
